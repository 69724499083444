import React, {useCallback, useEffect, useState} from 'react';
import b_ from 'b_';
import { Input, message, Table, Select } from 'antd';
import EditableField from '../../../Components/EditableField';
import PrintAccountState from '../../../Components/Prints/PrintAccountStatus';
import PrintPrice from '../../../Components/Prints/PrintPrice';
import TextArea from 'antd/lib/input/TextArea';
import ChangePassword from '../../Accounts/ChangePassword';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
    changeStatusAccount,
    updateBalLimitAccount,
    updateCommentAccount,
    updateCountryAccount,
    updateDriverAccount,
    updateClientAccount,
    updateEnabledJobsAccount,
    updateLoginAccount,
    updateProxyAccount,
    updatePurchLimitAccount, updateCurrencyAccount,
} from '../../../Reducers/account';
import CountrySelector from '../../Accounts/Selectors/CountrySelector';
import ChangeToken from '../../Accounts/ChangeToken';
import './styles.scss';
import Expanded from '../../Accounts/AccountsTable/Expanded';
import EnabledJobs from '../../../Components/EnabledJobs';
import EnableWithheld from '../../Accounts/EnableWithheld';
import EnablePending from '../../Accounts/EnablePending';
import ClientSelector from "../../Accounts/Selectors/ClientSelector";
import CurrencySelector from "../../../Components/CurrencySelector";

const b = b_.lock('AccountTable');

function AccountTable({ data, isLoading, afterUpdate, className }) {
  const dispatch = useDispatch();

  const {payload: clients} = useSelector(state => state.clients.list);

  const loadings = useSelector(state => ({
    proxy: state.account.updateProxy.isLoading,
    currency: state.account.updateCurrency.isLoading,
    driver: state.account.updateDriver.isLoading,
    comment: state.account.updateComment.isLoading,
    status: state.account.changeStatus.isLoading,
    login: state.account.updateLogin.isLoading,
    balLimit: state.account.updateBalLimit.isLoading,
    purchLimit: state.account.updatePurchLimit.isLoading,
    country: state.account.updateCountry.isLoading,
    client: state.account.updateClient.isLoading,
    enabledJobs: state.account.updateEnabledJobs.isLoading,
  }), shallowEqual);

  const updateEnabledJobs = useCallback((id, enabledJobs) => {
    dispatch(updateEnabledJobsAccount({ id, enabledJobs })).then(() => {
      message.success('Enabled Jobs has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update Enabled Jobs.');
    });
  }, [dispatch, afterUpdate]);

  const updateCountry = useCallback((id, country) => {
    dispatch(updateCountryAccount({ id, country })).then(() => {
      message.success('Country has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update country.');
    });
  }, [dispatch, afterUpdate]);

  const updatePurchLimit = useCallback((id, purchLimit) => {
    dispatch(updatePurchLimitAccount({ id, purchLimit: +purchLimit })).then(() => {
      message.success('Purch Limit has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update purch limit');
    });
  }, [dispatch, afterUpdate]);

  const updateBalLimit = useCallback((id, balLimit) => {
    dispatch(updateBalLimitAccount({ id, balLimit: +balLimit })).then(() => {
      message.success('Bal Limit has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update bal limit');
    });
  }, [dispatch, afterUpdate]);

  const updateLogin = useCallback((id, login) => {
    dispatch(updateLoginAccount({ id, login })).then(() => {
      message.success('Login has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update login');
    });
  }, [dispatch, afterUpdate]);

  const updateProxy = useCallback((id, proxy) => {
    dispatch(updateProxyAccount({ id, proxy })).then(() => {
      message.success('Proxy has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update proxy');
    });
  }, [dispatch, afterUpdate]);

  const updateDriver = useCallback((id, driver) => {
    dispatch(updateDriverAccount({ id, driver })).then(() => {
      message.success('Driver has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update driver');
    });
  }, [dispatch, afterUpdate]);

  const updateClient = useCallback((id, client) => {
    dispatch(updateClientAccount({ id, client })).then(() => {
      message.success('Client has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update client');
    });
  }, [dispatch, afterUpdate]);

  const updateComment = useCallback((id, comment) => {
    dispatch(updateCommentAccount({ id, comment })).then(() => {
      message.success('Comment has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update comment');
    });
  }, [dispatch, afterUpdate]);

  const updateStatus = useCallback((id, status) => {
    dispatch(changeStatusAccount({ id, action: status.toLowerCase() })).then(() => {
      message.success('Status has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update status');
    });
  }, [dispatch, afterUpdate]);

    const updateCurrency = useCallback((id, currency) => {
        dispatch(updateCurrencyAccount({id, currency})).then(() => {
            message.success('Currency has been updated!');
            afterUpdate();
        }).catch(error => {
            console.log(error);
            message.error('Can\'t update currency');
        });
    }, [dispatch, afterUpdate]);

  const GetColumnsTable = () => {
      return [{
          dataIndex: "id",
          title: "ID"
      },
      {
          dataIndex: "login",
          title: "Login",
          render:(login, rec) => (
              <EditableField handleSave={(newLogin) => updateLogin(rec.id, newLogin)}
                             title="Update Login"
                             withTitle
                             titlePopover="Edit Login"
                             isLoading={loadings.login || isLoading}
                             iconClassName={b('edit')}
                             initialValue={login}
                             changeBlock={({onChange, ...props}) => (
                                 <Input {...props}
                                        size="small"
                                        onChange={e => onChange(e.target.value)}/>
                             )}>
                  {login}
              </EditableField>
          ),
      },
      {
          dataIndex: "status",
          title: "Status",
          width: "120px",
          render: (status, rec) => (
            <>
                    <PrintAccountState
                        status={status}
                        field={"status"}
                        rec={rec}
                        handleSave={ updateStatus }
                    />
                    <EditableField 
                            handleSave={(newStatus) => updateStatus(rec.id, newStatus)}
                            title="Update Status"
                            withTitle
                            titlePopover="Edit Status"
                            isLoading={loadings.status || isLoading}
                            iconClassName={b('edit')}
                            initialValue={status}
                            changeBlock={(props) => (
                              <Select {...props} size="small">
                                <Select.Option value="Disable">Disable</Select.Option>
                                <Select.Option value="Enable">Enable</Select.Option>
                              </Select>
                            )} />
                </>
          ),
      },
      {
          dataIndex:"enabledJobs",
          title:"Enabled Jobs",
          render:(enabledJobs, rec) => (
              <EnabledJobs isLoading={loadings.enabledJobs || isLoading}
                           enabledJobs={enabledJobs}
                           iconClassName={b('edit')}
                           handleSave={(newStatus) => updateEnabledJobs(rec.id, newStatus)}
              />),
      },
      {
          dataIndex:"clientId",
          title:"Client",
          render: (value, rec) => (<EditableField
              handleSave={(newValue) => updateClient(rec.id, newValue)}
              title="Update Client"
              titlePopover="Edit Client"
              iconClassName={b('edit')}
              withTitle
              isLoading={loadings.client || isLoading}
              initialValue={value}
              changeBlock={(props) => (
                  <ClientSelector {...props}
                                  size="small"
                                  className="w100"
                  />
              )}>
              {(clients?.find(client => client.id === value)?.name) || 'Unknown'}
          </EditableField>)
      },
      {
          dataIndex: "country",
          title: "Country",
          render: (value, rec)=> (<EditableField
              handleSave={(newValue) => updateCountry(rec.id, newValue)}
              title="Update Country"
              titlePopover="Edit Country"
              iconClassName={b('edit')}
              withTitle
              isLoading={loadings.country || isLoading}
              initialValue={value}
              changeBlock={(props) => (
                  <CountrySelector {...props}
                                   size="small"
                                   className="w100"
                  />
              )}>
              {value}
          </EditableField>),
      },
      {
          dataIndex: "currency",
          title: "Currency",
          align: "center",
          defaultView: true,
          width: 50,
          render: (value, rec) => (<EditableField
              handleSave={(newValue) => updateCurrency(rec.id, newValue)}
              title="Update Country"
              titlePopover="Edit Country"
              iconClassName={b('edit')}
              withTitle
              isLoading={loadings.currency || isLoading}
              initialValue={value}
              changeBlock={(props) => (
                  <CurrencySelector {...props} value={value} />
              )}>
              {value}
          </EditableField>)
      },
      {
          dataIndex : "balance",
          title : "Balance",
          align : "right",
          render : value => <PrintPrice value={value}/>,
      },
      {
          dataIndex:"balLimit",
          title:"Limit",
          align:"right",
          render:(value, rec) => (<EditableField
              handleSave={(newValue) => updateBalLimit(rec.id, newValue)}
              title="Update BalLimit"
              titlePopover="Edit BalLimit"
              iconClassName={b('edit')}
              withTitle
              isLoading={loadings.balLimit || isLoading}
              initialValue={value}
              changeBlock={({onChange, ...props}) => (
                  <Input {...props}
                         size="small"
                         onChange={e => onChange(e.target.value)}/>
              )}>
              <PrintPrice value={value}/>
          </EditableField>),
      },
          {
              dataIndex:"purchLimit",
              title :"Purchase Limit",
              align :"right",
              render :(value, rec) => (<EditableField
          handleSave={(newValue) => updatePurchLimit(rec.id, newValue)}
          title="Update PurchLimit"
          titlePopover="Edit PurchLimit"
          iconClassName={b('edit')}
          withTitle
          isLoading={loadings.purchLimit || isLoading}
          initialValue={value}
          changeBlock={({onChange, ...props}) => (
              <Input {...props}
                     size="small"
                     onChange={e => onChange(e.target.value)}/>
          )}>
          <PrintPrice value={value}/>
      </EditableField>),
      },
      {
          dataIndex : "proxy",
          title : "Proxy",
          render : (value, rec) => (<EditableField
          handleSave={(newProxy) => updateProxy(rec.id, newProxy)}
          title="Update Proxy"
          titlePopover="Edit Proxy"
          iconClassName={b('edit')}
          withTitle
          isLoading={loadings.proxy || isLoading}
          initialValue={value}
          changeBlock={({onChange, ...props}) => (
              <Input {...props}
                     size="small"
                     onChange={e => onChange(e.target.value)}/>
          )}>
          {value}
      </EditableField>)
      },
      {
          dataIndex : "driver",
          title : "Driver",
          align : "right",
          render : (value, rec) => (<EditableField
          handleSave={(newDriver) => updateDriver(rec.id, newDriver)}
          title="Update Driver"
          titlePopover="Edit Driver"
          isLoading={loadings.driver || isLoading}
          iconClassName={b('edit')}
          withTitle
          initialValue={value}
          changeBlock={({onChange, ...props}) => (
              <Input {...props}
                     size="small"
                     type="number"
                     onChange={e => onChange(e.target.value)}/>
          )}>
          {value}
      </EditableField>),
      },
      {
          dataIndex: "comment",
          title: "Comment",
          render: (value, rec)  => (
          <EditableField handleSave={(newComment) => updateComment(rec.id, newComment)}
                         title="Update Comment"
                         titlePopover="Edit Comment"
                         isLoading={loadings.comment || isLoading}
                         iconClassName={b('edit')}
                         withTitle
                         initialValue={value}
                         changeBlock={({onChange, ...props}) => (
                             <TextArea {...props}
                                       size="small"
                                       onChange={e => onChange(e.target.value)}/>)}>
              {value}
          </EditableField>),
      },
      {
          dataIndex : "resultCode",
          title : "Result Code",
      },
      {
          dataIndex : "id",
          title : "Actions",
          render : (id, rec) => <div className={b('action')}>
          <div className={b('icon')}>
              <ChangePassword className="mr-small" accountId={id}/>
              <ChangeToken token={rec.authToken} accountId={id}/>
          </div>
          <div className={b('icon')}>
              <EnablePending accountId={id} afterSuccess={afterUpdate}/>
              <EnableWithheld accountId={id} afterSuccess={afterUpdate}/>
          </div>
      </div>
      } ]

  }
  const [columns, setColumns] = useState(GetColumnsTable())
    useEffect( () => {
        setColumns(GetColumnsTable())
    },[data, isLoading])
    const loader = Object.keys(loadings).some(e => !!loadings[e]) || isLoading

  return <Table bordered
                className={`${b()} ${className}`}
                loading={loader}
                size="small"
                scroll={{ x: 'max-content' }}
                dataSource={data}
                columns={columns}
                pagination={false}
                rowClassName={() => b('lock')}
                rowKey="id"
                expandable={{
                  expandedRowRender: record => <Expanded record={record} />,
                }}
  >

  </Table>;
}

export default AccountTable;
