import React, { useCallback } from 'react';
import {Drawer, Form, Input, message, Popover} from 'antd';
import useBoolean from '../../../Utils/useBoolean';
import ErrorBlock from '../../../Components/ErrorBlock';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { changePasswordAccount } from '../../../Reducers/accounts';
import {Button} from "@material-ui/core";
import {LoadingOutlined, LockOutlined} from "@ant-design/icons";

function ChangePassword({ className, accountId }) {
  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);
  const { error, isLoading } = useSelector(state => state.accounts.changePassword, shallowEqual);
  const dispatch = useDispatch();

  const handleSuccess = useCallback(({ password }) => {
    dispatch(changePasswordAccount({ accountId, password })).then(() => {
      message.success('Password has been updated!');
      close();
    });
  }, [dispatch, accountId, close]);

  return <>
      <Popover placement={"left"} content={"Change password"}>
          <LockOutlined onClick={open} />
      </Popover>
    <Drawer onClose={close}
            visible={isOpen}
            title="Change Password"
            placement="right"
            width={600}
    >
      {error && <ErrorBlock message={error.message} />}
      <Form layout="vertical" onFinish={handleSuccess}>
        <Form.Item
          rules={[
            { required: true, message: 'Please input new password.' },
          ]}
          label="New Password"
          name="password"
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item>
          <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ backgroundColor: "#1976d2"}}
              startIcon={isLoading && <LoadingOutlined />}>
            Save Password
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  </>;
};

export default ChangePassword;
