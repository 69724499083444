import React from 'react';
import {Popover, Switch, Tag} from 'antd';
import './styles.scss';
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";

const ENABLED_JOBS = JSON.parse(process.env.REACT_APP_ENABLED_JOBS);

function EnabledJobs({ enabledJobs, isLoading, handleSave, iconClassName }) {

    const handleUpdateTag = (job) => {
            return enabledJobs.includes(job) ? handleSave([ ...enabledJobs.filter(code => code !== job)])
                : handleSave([ ...enabledJobs.filter(em => em !== "Enabled"), job])
    }

    return (<div className="EnabledJobs">
      {ENABLED_JOBS.map((job) => (
          <Popover mouseEnterDelay={1.1} content={`${enabledJobs.includes(job) ? `remove from active ${job}` : `add to active ${job}` }`}>
              <Tag className={['EnabledJobs__tag', enabledJobs.includes(job) ? 'EnabledJobs__tag_green' : 'EnabledJobs__tag_red']}
                   color={enabledJobs.includes(job) ? 'green' : 'red'}
              >
                  {job} <Switch
                  size="small"
                  className={enabledJobs.includes(job) ? 'switch__green' : 'switch__red'}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={!!enabledJobs.includes(job)}
                  onClick={(e) => handleUpdateTag(job)}
              />
              </Tag>
          </Popover>
      ))}
    </div>);
}

export default EnabledJobs;
