import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';

function CurrencySelector({ className, value, defaultValue, onChange, disabled }) {

  return (
    <Select defaultValue={defaultValue}
            className={className}
            allowClear
            value={value}
            onChange={onChange}
            style={{ width: '100%' }}
            disabled={disabled}
    >
      <Select.Option value="EUR">EUR</Select.Option>
      <Select.Option value="USD">USD</Select.Option>
      <Select.Option value="RUB">RUB</Select.Option>
    </Select>
  )
}

CurrencySelector.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CurrencySelector;
