import ApiService from './ApiService';

export default {
  getList({ filters, orderPath, ...params }) {
    const updatedParams = { ...params };

    if (filters && Object.keys(filters).length) {
      for (let key in filters) {
        if (key === 'Dates') {
          updatedParams.fromTs = filters[key][0];
          updatedParams.toTs = filters[key][1];
        } else {
          updatedParams[key] = filters[key].join(',');
        }
      }
    }

    if (orderPath) {
      updatedParams.order = orderPath.replace('end', '');
    }

    return ApiService.get('/jobs', { ...updatedParams, jobType: 'PurchaseTracking' });
  },
  cancel(jobIds) {
    return ApiService.post('/job/cancel', { jobIds });
  }
};
