import React from 'react';
import { useRoutes } from "react-router-dom";
import RedirectUserToMainPage from '../Components/RedirectUserToMainPage';
import RedirectGuestToSignInPage from '../Components/RedirectGuestToSignInPage';
import Accounts from '../Pages/Accounts';
import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined';
import RedirectPage from '../Pages/RedirectPage';
import RedeemJob from '../Pages/RedeemJob';
import UnorderedListOutlined from '@ant-design/icons/lib/icons/UnorderedListOutlined';
import OtherJob from '../Pages/OtherJob';
import AccountPage from '../Pages/AccountPage';
import ProfileOutlined from '@ant-design/icons/lib/icons/ProfileOutlined';
import Config from '../Pages/Config';
import Products from '../Pages/Products';
import PurchaseJobs from '../Pages/PurchaseJobs';
import InvoiceJobs from '../Pages/InvoiceJobs';
import TrackingJobs from '../Pages/TrackingJobs';
import Driver from "../Pages/Driver";
import {AccountBookOutlined, BarChartOutlined, LineChartOutlined, ReadOutlined, SettingOutlined, ShopOutlined} from "@ant-design/icons";

function Routes() {
  const element = useRoutes([
    {
      path: '/sign-in',
      exact: true,
      element: <RedirectUserToMainPage/>,
    }, {
      path: '/redirect',
      exact: true,
      element: <RedirectPage/>,
    },
    {
      path: '/',
      element: <RedirectGuestToSignInPage/>,
      children: [
        {
          path: '/',
          exact: true,
          element: <Accounts/>,
        }, {
          path: '/redeem-job',
          exact: true,
          element: <RedeemJob/>,
        }, {
          path: '/other-job',
          exact: true,
          element: <OtherJob/>,
        }, {
          path: '/account/:id',
          exact: true,
          element: <AccountPage/>,
        }, {
          path: '/config',
          exact: true,
          element: <Config/>,
        }, {
          path: '/products',
          exact: true,
          element: <Products/>,
        }, {
          path: '/purchase-jobs',
          exact: true,
          element: <PurchaseJobs/>,
        }, {
          path: '/invoice-jobs',
          exact: true,
          element: <InvoiceJobs/>,
        }, {
          path: '/tracking-jobs',
          exact: true,
          element: <TrackingJobs/>,
        }, {
          path: '/drivers',
          exact: true,
          element: <Driver/>,
        },
      ],
    },
  ]);
  return element;
}

export const links = [
  {
    href: '/',
    icon: <UserOutlined />,
    title: 'Account',
  }, {
    href: '/redeem-job',
    icon: <ReadOutlined />,
    title: 'Redeem Jobs',
  }, {
    href: '/other-job',
    icon: <UnorderedListOutlined />,
    title: 'Other Jobs',
  }, {
    href: '/purchase-jobs',
    icon: <AccountBookOutlined />,
    title: 'Purchase Jobs',
  }, {
    href: '/invoice-jobs',
    icon: <BarChartOutlined />,
    title: 'Invoice Jobs',
  }, {
    href: '/tracking-jobs',
    icon: <LineChartOutlined />,
    title: 'Tracking Jobs',
  }, {
    href: '/config',
    icon: <ProfileOutlined />,
    title: 'Config',
  }, {
    href: '/products',
    icon: <ShopOutlined />,
    title: 'Products',
  }, {
    href: '/drivers',
    icon: <SettingOutlined />,
    title: 'Drivers',
  },
];

export default Routes;
