import { createAction, createReducer } from '@reduxjs/toolkit';
import { getDefaultHandler, getDefaultState } from '../Utils/newReducerTools';
import PurchaseJobService from '../Services/PurchaseJobService';

export const fetchPurchaseJobs = createAction('purchaseJobs/list', (params) => ({
  payload: PurchaseJobService.getList(params),
}));

export const cancelPurchaseJobs = createAction('purchaseJobs/cancel', (ids) => ({
  payload: PurchaseJobService.cancel(ids),
}));

export const sendOrderPurchaseJobs = createAction('purchaseJobs/orders', (data) => ({
  payload: PurchaseJobService.sendOrder(data),
}));

export const downloadInvoices = createAction('purchaseJobs/invoices', (ids) => ({
  payload: PurchaseJobService.downloadInvoices(ids),
}));

export const readTracking = createAction('purchaseJobs/tracking', (ids) => ({
  payload: PurchaseJobService.readTracking(ids),
}));

const initState = {
  list: getDefaultState(),
  cancel: getDefaultState(),
  orders: getDefaultState(),
  invoices: getDefaultState(),
  tracking: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(fetchPurchaseJobs, 'list'),
  ...getDefaultHandler(cancelPurchaseJobs, 'cancel'),
  ...getDefaultHandler(sendOrderPurchaseJobs, 'orders'),
  ...getDefaultHandler(downloadInvoices, 'invoices'),
  ...getDefaultHandler(readTracking, 'tracking'),
});
