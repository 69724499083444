import React, {useCallback, useState} from 'react';
import b_ from 'b_';
import {message} from 'antd';
import Title from 'antd/lib/typography/Title';
import useTableState from '../../Utils/useTableState';
import {cancelPurchaseJobs, fetchPurchaseJobs, downloadInvoices, readTracking} from '../../Reducers/purchaseJobs';
import JobsTable from '../../Components/JobsTable';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import JobFilters from '../../Components/JobFilters';
import './styles.scss';
import ErrorBlock from '../../Components/ErrorBlock';
import UploadOrders from './UploadOrders';
import {Button} from "@material-ui/core";
import {CloseCircleOutlined, DownloadOutlined, ReadOutlined} from "@ant-design/icons/lib/icons";
import useStorageState from "../../Utils/useStorageState";

const b = b_.lock('PurchaseJobs');

function PurchaseJobs() {
  const {fetch, error, onFilter, ...props} = useTableState(state => state.purchaseJobs.list, fetchPurchaseJobs);
  const [selectedRows, setSelectedRows] = useState([]);
  const [accountsGroups] = useStorageState('accountsChosenResellM');
  const dispatch = useDispatch();

  const cancelLoading = useSelector(state => state.purchaseJobs.cancel.isLoading, shallowEqual);

  const cancelJobs = useCallback(() => {
    dispatch(cancelPurchaseJobs(selectedRows)).then(() => {
      message.success('Jobs have been canceled!');
      setSelectedRows([]);
      fetch();
    });
  }, [dispatch, selectedRows, fetch, setSelectedRows]);

  const invoices = useCallback(() => {
    dispatch(downloadInvoices(selectedRows)).then(() => {
      message.success('Invoice jobs have been created for download!');
      setSelectedRows([]);
      fetch();
    });
  }, [dispatch, selectedRows, fetch, setSelectedRows]);

  const tracking = useCallback(() => {
    dispatch(readTracking(selectedRows)).then(() => {
      message.success('Tracking jobs have been created for download!');
      setSelectedRows([]);
      fetch();
    });
  }, [dispatch, selectedRows, fetch, setSelectedRows]);

  return <div className={b()} >
    <Title className={`mb ${b('title')}`} style={{flexWrap: 'wrap'}}>
      Purchase Jobs
    </Title>
    <div className={`mb ${b('buttons')}`}>
        <UploadOrders accountsGroups={accountsGroups} afterSuccess={fetch} className="mr-small"/>
        <Button
            onClick={invoices}
            className="mr-small"
            disabled={!selectedRows.length}
            startIcon={<DownloadOutlined />}
            color="primary"
            variant="contained"
            style={selectedRows.length ? { backgroundColor: "#4caf50"} : {}}
            loading={props.isLoading || cancelLoading}>
          Download Invoices
        </Button>
        <Button
            onClick={tracking}
            className="mr-small"
            disabled={!selectedRows.length}
            startIcon={<ReadOutlined />}
            color="primary"
            variant="contained"
            loading={props.isLoading || cancelLoading}>
          Read Tracking
        </Button>
        <Button
            onClick={cancelJobs}
            disabled={!selectedRows.length}
            startIcon={<CloseCircleOutlined />}
            color="secondary"
            variant="contained"
            loading={props.isLoading || cancelLoading}>
          Cancel Jobs
        </Button>
    </div>
    {error && <ErrorBlock className="mb" message={error.message || error}/>}
    <JobFilters filters={props.filters} onFilter={onFilter} className="mb"/>
    <JobsTable {...props} nameLocalStore="purchaseJobs" selected={selectedRows} setSelected={setSelectedRows}/>
  </div>;
}

export default PurchaseJobs;
