import React, {useCallback, useEffect, useState} from 'react';
import b_ from 'b_';
import PrintDriverStatus from '../../../Components/Prints/PrintDriverStatus';
import ErrorBlock from '../../../Components/ErrorBlock';
import UpdateDriver from '../UpdateDriver';
import UpdateVpn from '../UpdateVpn';
import './styles.scss';
import Expandable from './Expandable';
import useStorageState from "../../../Utils/useStorageState";
import ResizeTable from "../../../Components/ResizeableTitle";
import TableSettings from "../../Accounts/AccountsTable/TableSettings";

const b = b_.lock('DriverTable');

function DriverTable({ isLoading, error, payload, className, fetch }) {
    const [Checkbox, setCheckbox] = useState([]);

const GetColumnTable = () => {
    return [
        {
            dataIndex: "id", 
            title: "ID",
            width: 20,
        },
        {
            dataIndex: "group", 
            title: "Group",
            width: 50,
        },
        {
            dataIndex: "driver", 
            title: "Driver",
            width: 50,
        },
        {
            dataIndex: "host", 
            title: "Host",
            width: 50,
        },
        {
            dataIndex: "port", 
            title: "Port",
            width: 50,
        },
        {
            dataIndex: "type", 
            title: "Type",
            width: 50,
        },
        {
            dataIndex: "uses", 
            title: "Uses",
            width: 50,
        },
        {
            dataIndex: "status", 
            title: "Status",
            width: 50,
            render: (status) => <PrintDriverStatus status={status} />
        },
        {
            dataIndex: "vpn", 
            title: "VPN",
            width: 50,
        },
        {
            dataIndex: "vpnProvider", 
            title: "Provider",
            width: 50,
        },
        {
            dataIndex: "vpnStatus", 
            title: "VPN Status",
            width: 50,
            render: (vpnStatus, record) => { if(record.updating === true) { return 'Updating...' } else { return vpnStatus } }
        },
        {
            dataIndex: "id",
            title: "Actions",
            align: "center",
            width: 50,
            render: (id, record) => (
                <div className={b('actions')}>
                    <UpdateDriver record={record} afterUpdate={fetch} id={id} />
                    <UpdateVpn afterUpdate={fetch} id={id} />
                </div>)
        },
    ]
}


    const [AllCheckbox, setAllCheckbox] = useStorageState('drivers', {
        checkedList: Checkbox,
        indeterminate: false,
        checkAll: true,
    });

    const onChangeCheckList = useCallback((checkedList) => {
        setAllCheckbox({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < columns.length,
            checkAll: checkedList.length === columns.length,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Checkbox]);

    useEffect(() => {
        let allCheckList = []

        if(payload && !Checkbox.length) {
            GetColumnTable().forEach((e) => {
                allCheckList.push( e[Object.keys(e)[1]])
                setCheckbox(allCheckList)
                return;
            })
        }

    }, [payload, AllCheckbox])

    useEffect(() => {
        if(AllCheckbox.checkAll) {
            setAllCheckbox({
                checkedList: GetColumnTable().map(e => e.title),
                indeterminate: false,
                checkAll: true,
            })
        }
    },[AllCheckbox.checkAll])

    const onChangeCheckAll = useCallback((e) => {
        setAllCheckbox({
            checkedList: (e.target.checked && columns.map(e => e.title)) || [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Checkbox]);

    const [columns, setColumns] = useState(GetColumnTable());


    useEffect(() => {
        setColumns(GetColumnTable())
    }, [])
    

    const columnsFilter = columns.filter(e => AllCheckbox.checkedList.indexOf(e.title) !== -1);

  return (
    <>
      {error && <ErrorBlock message={error.message} description={error.description} />}
      <ResizeTable bordered
             className={className}
             loading={isLoading}
             pagination={false}
             scroll={{ x: 'max-content' }}
             dataSource={payload}
             rowKey="id"
             columns={columnsFilter}
             style={{ wordBreak: "break-word", wordWrap: "break-word" }}
             title={() => <TableSettings
                 onChangeCheckAll={onChangeCheckAll}
                 allChecks={AllCheckbox}
                 Checkboxs={AllCheckbox.checkedList}
                 TableColumn={GetColumnTable}
                 setCheckbox={onChangeCheckList}
             />}
             expandable={{
               expandedRowRender: record => <Expandable record={record} />,
             }}
      >
      </ResizeTable>
    </>
  );
}

export default DriverTable;
