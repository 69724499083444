import ApiService from './ApiService';

export default {
  getList({ filters, orderPath, ...params }) {
    const updatedParams = { ...params };

    if (filters && Object.keys(filters).length) {
      for (let key in filters) {
        updatedParams[key] = Array.isArray(filters[key]) ? filters[key].join(',') : filters[key];
      }
    }

    if (orderPath) {
      updatedParams.order = orderPath.replace('end', '');
    }

    return ApiService.get('/accounts', updatedParams);
  },
  changeProxy(params) {
    const { id, proxy } = params;
    return ApiService.post('/account/update', { accountIds: [id], proxy });
  },
  changeEnabledJobs(params) {
    const { id, enabledJobs } = params;
    return ApiService.post('/account/update', { accountIds: [id], enabledJobs });
  },
  changeBalLimit(params) {
    const { id, balLimit } = params;
    return ApiService.post('/account/update', { accountIds: [id], balLimit });
  },
  changePurchLimit(params) {
    const { id, purchLimit } = params;
    return ApiService.post('/account/update', { accountIds: [id], purchLimit });
  },
  changeLogin(params) {
    const { id, login } = params;
    return ApiService.post('/account/update', { accountIds: [id], login });
  },
  changeCountry(params) {
    const { id, country } = params;
    return ApiService.post('/account/update', { accountIds: [id], country });
  },
  changeCurrency(params) {
      const { id, currency } = params;
      return ApiService.post('/account/update', { accountIds: [id], currency });
  },
  changeDriver(params) {
    const { id, driver } = params;
    return ApiService.post('/account/update', { accountIds: [id], driver });
  },
  changeClient(params) {
    const { id, client } = params;
    return ApiService.post('/account/update', { accountIds: [id], clientId: client });
  },
  changeComment(params) {
    const { id, comment } = params;
    return ApiService.post('/account/update', { accountIds: [id], comment });
  },
  createAccount(data) {
    return ApiService.put('/account', data);
  },
  updateAccounts(data) {
    const updatedData = Object.keys(data).reduce((newData, key) => {
      if (![null, undefined].includes(data[key])) {
        newData[key] = data[key];
      }
      return newData;
    }, {});

    return ApiService.post('/account/update', updatedData);
  },
  changePassword(data) {
    return ApiService.post('/account/set_password', data);
  },
  changeToken(data) {
    return ApiService.post('/account/update', data);
  },
  changeStatus(data) {
    const { id, action } = data;
    return ApiService.post('/job/no_arg', { action, accountIds: [id] });
  },
  enableWithheld(data) {
    const { id  } = data;
    return ApiService.post('/job/no_arg', { action: 'enable-withheld', accountIds: [id] });
  },
  enablePending(data) {
    const { id  } = data;
    return ApiService.post('/job/no_arg', { action: 'enable-pending', accountIds: [id] });
  },
  fetchAccountGroupBalance() {
    return ApiService.get('/group_balance').then((data = []) => {
      const all = data.reduce((acc, cur) => {
        acc.allBal += cur.allBal;
        acc.allCnt += cur.allCnt;
        acc.enBal += cur.enBal;
        acc.enCnt += cur.enCnt;
        acc.disBal += cur.disBal;
        acc.disCnt += cur.disCnt;
        acc.invBal += cur.invBal;
        acc.invCnt += cur.invCnt;
        return acc;
      }, {
        country: 'ALL',
        allBal: 0,
        allCnt: 0,
        enBal: 0,
        enCnt: 0,
        disBal: 0,
        disCnt: 0,
        invBal: 0,
        invCnt: 0,
        balToAll: 1,
      });

      const updData = data.map(v => ({ ...v, balToAll: v.allBal / all.allBal }));
      updData.push(all);

      return updData;
    });
  },
  fetchAux() {
    return ApiService.get('/accounts/aux');
  },
  fetchAccount(id) {
    return ApiService.get('/account', { account_id: id });
  },
  getJobs(data) {
    return ApiService.get('/jobs', data);
  },
  disableAccounts(data) {
    return ApiService.post('/job/no_arg', data);
  },
  enableAccounts(data) {
    return ApiService.post('/job/no_arg', data);
  },
};
