import React, { useCallback, useEffect, useState } from 'react';
import b_ from 'b_';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Title from 'antd/lib/typography/Title';
import { Table } from 'antd';
import { fetchGroupBalanceAccount } from '../../../Reducers/accounts';
import './styles.scss';
import PrintPrice from '../../../Components/Prints/PrintPrice';
import ErrorBlock from '../../../Components/ErrorBlock';
import PrintPercent from '../../../Components/Prints/PrintPercent';

const b = b_.lock('AccountGroupBalance');

function AccountGroupBalance({ className }) {
  const { payload, isLoading, error } = useSelector(state => state.accounts.groupBalance, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGroupBalanceAccount());
  }, [dispatch]);

  const columns = [
    {
      title: "Country",
      align: "center",
      dataIndex: "country",
      width: 59,
    },
    {
      title: "All",
      align: "center",
      dataIndex: "allBal",
      width: 110,
      render: (bal, rec) => (
        <div className={b('col')}>
          <PrintPrice value={bal} currency="EUR" />
          <small className={b('count')}>({rec.allCnt})</small>
        </div>
      )
    },
    {
      title: "Enabled",
      align: "center",
      dataIndex: "enBal",
      width: 110,
      render: (bal, rec) => (
        <div className={b('col')}>
          <PrintPrice value={bal} currency="EUR" />
          <small className={b('count')}>({rec.enCnt})</small>
        </div>
      )
    },
    {
      title: "Invalid",
      align: "center",
      dataIndex: "invBal",
      width: 110,
      render: (bal, rec) => (
        <div className={b('col')}>
          <PrintPrice value={bal} currency="EUR" />
          <small className={b('count')}>({rec.invCnt})</small>
        </div>
      )
    },
    {
      title: "Disabled",
      align: "center",
      dataIndex: "disBal",
      width: 110,
      render: (bal, rec) => (
        <div className={b('col')}>
          <PrintPrice value={bal} currency="EUR" />
          <small className={b('count')}>({rec.disCnt})</small>
        </div>
      )
    },
    {
      title: "Codes Rates",
      align: "center",
      dataIndex: "codesRates",
      width: 120,
    },
    {
      title: "Balance to ALL %",
      align: "center",
      dataIndex: "balToAll",
      render: value => <PrintPercent value={value} />,
    },
  ]

  return (<div className={className}>
    <Title level={2} style={{width: "800px"}} className="mb">
      Account Group Balance
    </Title>
    {error && <ErrorBlock className="mb" message={error.message} description={error.description} />}
    <Table bordered
           dataSource={payload}
           loading={isLoading}
           scroll={{ x: "max-content" }}
           rowKey="country"
           className={b('table')}
           pagination={false}
           columns={columns}
    />
  </div>);

}

export default AccountGroupBalance;
