import React from 'react';
import { Select } from 'antd';
import ErrorBlock from '../ErrorBlock';

const VPN_PROVIDER = process.env.REACT_APP_VPN_PROVIDERS;
let parsedVpnProvider = null;

try {
  parsedVpnProvider = JSON.parse(VPN_PROVIDER);
} catch (e) {
  console.log('parsedVpnProvider e:', e);
}

function VpnProviderSelector({ value, onChange, className, placeholder, defaultValue, disabled }) {
  if (!parsedVpnProvider) {
    return <ErrorBlock message="ENV REACT_APP_VPN_PROVIDERS is not set!" />
  }

  return (<Select defaultValue={defaultValue}
                  placeholder={placeholder}
                  className={className}
                  allowClear
                  value={value}
                  onChange={onChange}
                  style={{ width: '100%' }}
                  disabled={disabled}
  >
    {parsedVpnProvider.map(provider => <Select.Option value={provider}>{provider}</Select.Option>)}
  </Select>)
}

export default VpnProviderSelector;
