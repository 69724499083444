import React, { useCallback } from 'react';
import {Drawer, Form, Input, message, Popover} from 'antd';
import useBoolean from '../../../Utils/useBoolean';
import ErrorBlock from '../../../Components/ErrorBlock';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { changeTokenAccount } from '../../../Reducers/accounts';
import {KeyOutlined, LoadingOutlined} from "@ant-design/icons";
import {Button} from "@material-ui/core";

function ChangeToken({ className, accountId, token }) {
  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);
  const { error, isLoading } = useSelector(state => state.accounts.changeToken, shallowEqual);
  const dispatch = useDispatch();

  const handleSuccess = useCallback(({ token }) => {
    dispatch(changeTokenAccount({ accountIds: [accountId], authToken: token })).then(() => {
      message.success('Token has been updated!');
      close();
    });
  }, [dispatch, accountId, close]);

  return <>
      <Popover placement="topLeft" content={"Change token"}>
          <KeyOutlined onClick={open} />
      </Popover>
    <Drawer onClose={close}
            visible={isOpen}
            title="Change Token"
            placement="right"
            width={600}
    >
      {error && <ErrorBlock message={error.message} />}
      <Form layout="vertical" onFinish={handleSuccess}>
        <Form.Item
          rules={[
            { required: true, message: 'Please input new token.' },
          ]}
          label="New Token"
          name="token"
          initialValue={token}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item>
          <Button
              color="primary"
              type="submit"
              variant="contained"
              style={{ backgroundColor: "#1976d2"}}
              startIcon={isLoading && <LoadingOutlined />}>
            Save Token
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  </>;
};

export default ChangeToken;
