import React from 'react';
import b_ from 'b_';
import Title from 'antd/lib/typography/Title';
import useTableState from '../../Utils/useTableState';
import { fetchProductList } from '../../Reducers/products';
import ProductTable from './ProductTable';
import AddProduct from './AddProduct';
import './styles.scss';

const b = b_.lock('Products');

function Products() {
  const { list, isLoading, pagination, onChange, fetch } = useTableState(state => state.products.list, fetchProductList);

  return <div>
    <Title className={b('title')}>
      Products
    </Title>
    <div className={`mb ${b('buttons')}`}>
      <AddProduct afterSuccess={fetch} />
    </div>
    <ProductTable list={list} isLoading={isLoading} pagination={pagination} onChange={onChange} />
  </div>;
}

export default Products;
