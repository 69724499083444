import React from 'react';
import { connect } from 'react-redux';
import Routes from '../Routes';
import './styles.css';

class App extends React.Component {
  render() {
    return <div className="App">
      <Routes/>
    </div>;
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
