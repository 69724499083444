import React, { useCallback, useReducer, useState } from 'react';
import b_ from 'b_';
import { Checkbox, Drawer, Form, Input, message, Popconfirm } from 'antd';
import useBoolean from '../../../Utils/useBoolean';
import CountrySelector from '../Selectors/CountrySelector';
import CurrencySelector from '../Selectors/CurrencySelector';
import TextArea from 'antd/lib/input/TextArea';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ErrorBlock from '../../../Components/ErrorBlock';
import { updateAccounts } from '../../../Reducers/accounts';
import EditFilled from '@ant-design/icons/lib/icons/EditFilled';
import SelectEnabledJobs from '../../../Components/SelectEnabledJobs';
import ClientSelector from "../Selectors/ClientSelector";
import {Button, makeStyles} from "@material-ui/core";

const b = b_.lock('CreateAccount');

const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: 8,
        marginLeft: 8,
    },
}));

function UpdateAccounts({ className, afterSuccess, selected }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const isLoading = useSelector(state => state.accounts.updateAccounts.isLoading, shallowEqual);

  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);

  const [resetFields, setResetFields] = useState([]);

  const changeResetFields = useCallback((field) => {
    if (resetFields.includes(field)) {
      return setResetFields(resetFields.filter(v => v !== field));
    }

    setResetFields([...resetFields, field]);
  }, [setResetFields, resetFields]);

  const handleSuccess = useCallback((formFields) => {
    const someExist = Object.keys(formFields).some(key => formFields[key]);

    if (!someExist && resetFields.length === 0) {
      return message.error('Please input one of field');
    }

    const fields = {...formFields, ...resetFields.reduce((acc, cur) => ({ ...acc, [cur]: cur === "enabledJobs" ? [] : '' }), {})};

    const data = { accountIds: selected, ...fields };
    dispatch(updateAccounts(data)).then(() => {
      close();
      afterSuccess();
      message.success('Accounts has been updated!');
      form.resetFields();
    });
  }, [close, afterSuccess, dispatch, selected, resetFields, form]);

  const error = useSelector(state => state.accounts.updateAccounts.error, shallowEqual);
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  return (
    <>
      <Button
          disabled={!selected.length}
          size="medium"
          color="primary"
          variant="contained"
          className={classes.button}
          startIcon={<EditFilled />}
          style={selected.length ? { backgroundColor: "#4383f2"}: {}}
          onClick={open}
      >
        Update {selected.length} accounts
      </Button>
      <Drawer visible={isOpen}
              title={`Update Accounts: ${selected.length}`}
              width={600}
              closable
              destroyOnClose
              placement="right"
              onClose={close}
              forceRender
      >
        {error && <ErrorBlock message={error.message} />}
        <Form onFieldsChange={forceUpdate} form={form} layout="vertical" className={b()} onFinish={handleSuccess}>
          <Form.Item
            label="Client"
            name="clientId"
            hasFeedback={form.getFieldValue('clientId')}
            validateStatus="success"
          >
            <ClientSelector disabled={resetFields.includes('clientId')} placeholder="Select client" />
          </Form.Item>
          <Form.Item
            label="Country"
            name="country"
            hasFeedback={form.getFieldValue('country')}
            validateStatus="success"
          >
            <CountrySelector disabled={resetFields.includes('country')} placeholder="Select country" />
          </Form.Item>
          <Form.Item
            label="Currency"
            hasFeedback={form.getFieldValue('currency')}
            validateStatus="success"
            name="currency"
          >
            <CurrencySelector disabled={resetFields.includes('currency')} placeholder="Select currency" />
          </Form.Item>
          <Form.Item
            label={<div>
                <span className="mr-small">Enabled Jobs</span>
                <span className="mr-small">Reset field</span>
                <Checkbox checked={resetFields.includes('enabledJobs')}
                          onChange={() => changeResetFields('enabledJobs')}
                />
            </div>}
            hasFeedback={form.getFieldValue('enabledJobs')?.length }

            validateStatus="success"
            name="enabledJobs"
          >
            <SelectEnabledJobs disabled={resetFields.includes('enabledJobs')} />
          </Form.Item>
          <Form.Item
            label="Limit"
            name="balLimit"
            hasFeedback={form.getFieldValue('balLimit')}
            validateStatus="success"
            normalize={v => v ? +v : ''}
          >
            <Input disabled={resetFields.includes('limit')} type="number" />
          </Form.Item>
          <Form.Item
            label="Purchase Limit"
            name="purchLimit"
            hasFeedback={form.getFieldValue('purchLimit')}
            validateStatus="success"
            normalize={v => v ? +v : ''}
          >
            <Input disabled={resetFields.includes('purchLimit')} type="number" />
          </Form.Item>
          <Form.Item
            label={<div>
              <span className="mr">Driver</span>
              <span className="mr-small">Reset field</span>
              <Checkbox checked={resetFields.includes('driver')}
                        onChange={() => changeResetFields('driver')}
              />
            </div>}
            name="driver"
            hasFeedback={form.getFieldValue('driver')}
            validateStatus="success"
          >
            <Input disabled={resetFields.includes('driver')} />
          </Form.Item>
          <Form.Item
            label={<div>
              <span className="mr">Comment</span>
              <span className="mr-small">Reset field</span>
              <Checkbox checked={resetFields.includes('comment')}
                        onChange={() => changeResetFields('comment')}
              />
            </div>}
            name="comment"
            hasFeedback={form.getFieldValue('comment')}
            validateStatus="success"
          >
            <TextArea disabled={resetFields.includes('comment')} />
          </Form.Item>
          <Form.Item>
            <Popconfirm
              title={`Are you sure update ${selected.length} task?`}
              onConfirm={form.submit}
              okText="Yes"
              cancelText="No"
            >
              <Button loading={isLoading}
                      color="primary"
                      style={{backgroundColor: "#1976d2"}}
                      variant="contained">
                Submit
              </Button>
            </Popconfirm>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default UpdateAccounts;
