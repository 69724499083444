import { createAction, createReducer } from '@reduxjs/toolkit';
import { getDefaultHandler, getDefaultState } from '../Utils/newReducerTools';
import AccountService from '../Services/AccountService';
import RedeemJobService from '../Services/RedeemJobService';

export const fetchAccount = createAction('account/get', (id) => ({
  payload: AccountService.fetchAccount(id),
}));

export const updateProxyAccount = createAction('account/update-proxy', (params) => ({
  payload: AccountService.changeProxy(params),
}));

export const updateDriverAccount = createAction('account/update-driver', (param) => ({
  payload: AccountService.changeDriver(param),
}));

export const updateClientAccount = createAction('account/update-client', (params) => ({
  payload: AccountService.changeClient(params),
}));

export const updateCommentAccount = createAction('account/update-comment', (param) => ({
  payload: AccountService.changeComment(param),
}));

export const changeStatusAccount = createAction('account/change-status', (data) => ({
  payload: AccountService.changeStatus(data),
}));

export const fetchJobsAccount = createAction('account/jobs', (data) => ({
  payload: AccountService.getJobs(data),
}));

export const updateLoginAccount = createAction('account/update-login', (param) => ({
  payload: AccountService.changeLogin(param),
}));

export const updateBalLimitAccount = createAction('account/update-balLimit', (param) => ({
  payload: AccountService.changeBalLimit(param),
}));

export const updatePurchLimitAccount = createAction('account/update-purchLimit', (param) => ({
  payload: AccountService.changePurchLimit(param),
}));

export const updateCountryAccount = createAction('account/update-country', (param) => ({
  payload: AccountService.changeCountry(param),
}));

export const updateCurrencyAccount = createAction('account/update-currency', (param) => ({
  payload: AccountService.changeCurrency(param),
}));

export const updateEnabledJobsAccount = createAction('account/update-enabledJobs', (param) => ({
  payload: AccountService.changeEnabledJobs(param),
}));

export const cancelJobsAccount = createAction('account/cancel-jobs', (data) => ({
  payload: RedeemJobService.cancel(data),
}));

const initState = {
  account: getDefaultState(),
  updateProxy: getDefaultState(),
  updateCurrency: getDefaultState(),
  updateDriver: getDefaultState(),
  updateClient: getDefaultState(),
  changeStatus: getDefaultState(),
  updateComment: getDefaultState(),
  updateLogin: getDefaultState(),
  updateBalLimit: getDefaultState(),
  updatePurchLimit: getDefaultState(),
  updateCountry: getDefaultState(),
  updateEnabledJobs: getDefaultState(),
  jobs: getDefaultState(),
  cancelJobs: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(fetchAccount, 'account'),
  ...getDefaultHandler(updateProxyAccount, 'updateProxy'),
  ...getDefaultHandler(updateDriverAccount, 'updateDriver'),
  ...getDefaultHandler(updateClientAccount, 'updateClient'),
  ...getDefaultHandler(updateCommentAccount, 'updateComment'),
  ...getDefaultHandler(changeStatusAccount, 'changeStatus'),
  ...getDefaultHandler(fetchJobsAccount, 'jobs'),
  ...getDefaultHandler(cancelJobsAccount, 'cancelJobs'),
  ...getDefaultHandler(updateLoginAccount, 'updateLogin'),
  ...getDefaultHandler(updateBalLimitAccount, 'updateBalLimit'),
  ...getDefaultHandler(updatePurchLimitAccount, 'updatePurchLimit'),
  ...getDefaultHandler(updateCountryAccount, 'updateCountry'),
  ...getDefaultHandler(updateCurrencyAccount, 'updateCurrency'),
  ...getDefaultHandler(updateEnabledJobsAccount, 'updateEnabledJobs'),
});
