import ApiService from './ApiService';

export default {
  fetchDrivers() {
    return ApiService.get('/drivers');
  },
  vpnLookup(provider, query) {
    return ApiService.get('/vpn_names', { provider, query });
  },
  updateVpn(data) {
    return ApiService.put('/vpn', data)
  },
  update(data) {
    return ApiService.post('/driver', data)
  },
   create(data) {
    return ApiService.put('/driver', data)
  },
}
