import { createAction, createReducer } from '@reduxjs/toolkit';
import { getDefaultHandler, getDefaultState } from '../Utils/newReducerTools';
import TrackingJobService from "../Services/TrackingJobService";

export const fetchTrackingJobs = createAction('trackingJobs/list', (params) => ({
  payload: TrackingJobService.getList(params),
}));

export const cancelTrackingJobs = createAction('trackingJobs/cancel', (ids) => ({
  payload: TrackingJobService.cancel(ids),
}));

const initState = {
  list: getDefaultState(),
  cancel: getDefaultState()
};

export default createReducer(initState, {
  ...getDefaultHandler(fetchTrackingJobs, 'list'),
  ...getDefaultHandler(cancelTrackingJobs, 'cancel'),
});
