import React from 'react';
import { Select } from 'antd';
import ErrorBlock from '../ErrorBlock';

const DRIVER_TYPE = process.env.REACT_APP_DRIVERS_TYPE;
let parsedDriverType = null;

try {
  parsedDriverType = JSON.parse(DRIVER_TYPE);
} catch (e) {
  console.log('parsedDriverType e:', e);
}

function DriversTypeSelector({ value, onChange, className, placeholder, defaultValue, disabled }) {
  if (!parsedDriverType) {
    return <ErrorBlock message="ENV REACT_APP_DRIVERS_TYPE is not set!" />
  }

  return (<Select defaultValue={defaultValue}
                  placeholder={placeholder}
                  className={className}
                  allowClear
                  value={value}
                  onChange={onChange}
                  style={{ width: '100%' }}
                  disabled={disabled}
  >
    {parsedDriverType.map(driver => <Select.Option value={driver}>{driver}</Select.Option>)}
  </Select>)
}

export default DriversTypeSelector;
