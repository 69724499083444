import React, { useCallback } from 'react';
import {message, Popconfirm, Popover} from 'antd';
import AccountService from '../../../Services/AccountService';
import useDefaultRequestReducer from '../../../Utils/useDefaultRequestReducer';
import {PhoneOutlined , LoadingOutlined} from "@ant-design/icons";

function EnableWithheld({ className, accountId, afterSuccess }) {
  const { state: { isLoading }, fetch } = useDefaultRequestReducer(AccountService.enableWithheld);

  const update = useCallback(() => {
    fetch({ id: accountId }).then(() => {
      message.success('Success enable withheld!');
      afterSuccess();
    }).catch(() => {
      message.error('Something went wrong!');
    });
  }, [accountId, fetch, afterSuccess]);

  return (
    <Popconfirm placement="topLeft"
                title={`Are you sure enable withheld?`}
                onConfirm={update}
                okText="Yes"
                cancelText="No"
    >
        <Popover placement="bottomRight" content={"enable withheld"}>
            {!isLoading ? <PhoneOutlined /> : <LoadingOutlined />}
        </Popover>
    </Popconfirm>
  );
}

export default EnableWithheld;
