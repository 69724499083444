import { createAction, createReducer } from '@reduxjs/toolkit';
import { getDefaultHandler, getDefaultState } from '../Utils/newReducerTools';
import RedeemJobService from '../Services/RedeemJobService';

export const fetchRedeemJobs = createAction('redeemJobs/list', (params) => ({
  payload: RedeemJobService.getList(params),
}));

export const cancelRedeemJobs = createAction('redeemJobs/cancel', (ids) => ({
  payload: RedeemJobService.cancel(ids),
}));

const initState = {
  list: getDefaultState(),
  cancel: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(fetchRedeemJobs, 'list'),
  ...getDefaultHandler(cancelRedeemJobs, 'cancel'),
});
