import React, {useCallback, useEffect, useState} from 'react';
import b_ from 'b_';
import { JSONTree } from 'react-json-tree';
import PrintJobStatus from '../Prints/PrintJobStatus';
import PrintDate from '../Prints/PrintDate';
import theme from '../../Utils/jsonTheme';
import Expandable from './ Expandable';
import PrintFromNow from '../Prints/PrintFromNow';
import ImageViews from '../ImageViews';
import PageScreens from '../PageScreens';
import { Link } from 'react-router-dom';
import './styles.scss';
import TableSettings from "../../Pages/Accounts/AccountsTable/TableSettings";
import useStorageState from "../../Utils/useStorageState";
import ResizeTable from "../ResizeableTitle";

const b = b_.lock('JobTable');

function JobsTable({ list, pagination, onChange, isLoading, selected, setSelected, nameLocalStore, filters }) {
    const [Checkbox, setCheckbox] = useState([]);

    const [AllCheckbox, setAllCheckbox] = useStorageState(nameLocalStore, {
        checkedList: Checkbox,
        indeterminate: false,
        checkAll: true,
    });

    const GetColumnTable = () => {
        return [
            {
                dataIndex:"id",
                title:"ID",
                width: 77,
            },
            {
                dataIndex:"createTs",
                title:"Created",
                width: 175,
                render:(create) => <PrintDate withTime date={create} />,
            },
            {
                dataIndex:"updateTs",
                title:"Updated",
                width: 91,
                render: (update, rec) => <PrintFromNow date={rec.createTs} start={update} />,
            },
            {
                dataIndex:"client",
                title:"Client",
                width: 80,
            },
            {
                dataIndex:"workerGroup",
                title:"Group",
                width: 74,
            },
            {
                dataIndex:"accountName",
                title:"Account Name",
                width: 199,
                render: (name, rec) => <Link to={`/account/${rec.accountId}`}>{name}</Link>,
            },
            {
                dataIndex:"status",
                title:"Status",
                width: 91,
                filters: [
                    {value: "Done", text:"Done"},
                    {value: "New", text:"New"},
                    {value: "Error", text:"Error"}
                    ],
                filteredValue: filters['status'] || null,
                filterMultiple: true,
                render: status => <PrintJobStatus jobStatus={status} />,
            },
            {
                dataIndex:"phase",
                title:"Phase",
                width: 98,
            },

            {
                dataIndex:"action",
                title:"Action",
                width: 463,
                render: json => <JSONTree hideRoot theme={theme} data={json} />
            },

            {
                dataIndex : "resultCode",
                title : "Result Code",
                width: 215,
            },
            {
                dataIndex : "screens",
                title : "Screens",
                width: 140,
                render : images => (<ImageViews images={images}/>),
            },

            {
                dataIndex : "pages",
                title : "Pages",
                width: 140,
                render : pages=> <PageScreens pages={pages}/>
            }
            ]
    }

    useEffect(() => {
        let allCheckList = []

        if(list.length && !Checkbox.length) {
            GetColumnTable().forEach((e) => {
                allCheckList.push( e[Object.keys(e)[1]])
                setCheckbox(allCheckList)
                return;
            })
        }

    }, [list, AllCheckbox])

    const [columns, setColumns] = useState(GetColumnTable());

    useEffect(() => {
        if(AllCheckbox.checkAll) {
            setAllCheckbox({
                checkedList: GetColumnTable().map(e => e.title),
                indeterminate: false,
                checkAll: true,
            })
        }
    },[AllCheckbox.checkAll])

    const onChangeCheckList = useCallback((checkedList) => {
        setAllCheckbox({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < columns .length,
            checkAll: checkedList.length === columns.length,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Checkbox]);

    const onChangeCheckAll = useCallback((e) => {
        setAllCheckbox({
            checkedList: (e.target.checked && columns.map(e => e.title)) || [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Checkbox]);
    
    useEffect(() => {
        setColumns(GetColumnTable())
    }, [filters, list])

    const columnsFilter = columns.filter(e => AllCheckbox.checkedList.indexOf(e.title) !== -1);
    
  return <ResizeTable bordered
                className={b()}
                scroll={{ y: "calc(100vh - 125px)", x: "max-content" }}
                size="middle"
                dataSource={list}
                rowKey="id"
                columns={columnsFilter}
                title={() => <TableSettings
                    onChangeCheckAll={onChangeCheckAll}
                    allChecks={AllCheckbox}
                    Checkboxs={AllCheckbox.checkedList}
                    TableColumn={GetColumnTable}
                    setCheckbox={onChangeCheckList}
                />}
                onChange={onChange}
                pagination={pagination}
                loading={isLoading}
                rowSelection={{
                  selectedRowKeys: selected,
                  onChange: setSelected,
                }}
                expandable={{
                  expandedRowRender: record => <Expandable record={record} />,
                }}
  >

  </ResizeTable>;
}

export default JobsTable;
