import React, { useCallback, useState } from 'react';
import b_ from 'b_';
import { Col, DatePicker, Input, Row } from 'antd';
import './styles.scss';
import { momentToUnix, unixToMoment } from '../../Utils/dates';
import {Button} from "@material-ui/core";

const RangePicker = DatePicker.RangePicker;

const b = b_.lock('JobFilters');

function JobFilters({ className, filters, onFilter, withCodeFilter = true }) {
  const [curFilters, setCurFilters] = useState(filters);

  const handleChangeFilter = useCallback((value, field) => {
    const preparedValue = Array.isArray(value) ? value : value === '' ? null : [value];
    setCurFilters({ ...curFilters, [field]: preparedValue });
  }, [curFilters, setCurFilters]);

  return <Row gutter={16} justify={'space-between'} className={`${className} ${b()}`}>
    <Col >
      <span className={b('label')}>JobId</span>
      <Input value={curFilters.jobId ? curFilters.jobId[0] : null}
             className={b('item')}
             onChange={e => handleChangeFilter(e.target.value, 'jobId')}
      />
    </Col>
    <Col >
      <span className={b('label')}>Dates</span>
      <RangePicker showTime
                   value={curFilters.Dates ? curFilters.Dates.map(unixToMoment) : [null, null]}
                   className={b('item')}
                   onChange={dates => handleChangeFilter(dates ? dates.map(momentToUnix) : null, 'Dates')}
      />
    </Col>
    {withCodeFilter && <Col >
      <span className={b('label')}>Code</span>
      <Input className={b('item')}
             value={curFilters.code ? curFilters.code[0] : null}
             onChange={e => handleChangeFilter(e.target.value, 'code')}
      />
    </Col>}
    <Col >
      <span className={b('label')} />
      <Button
          variant="contained"
          size="small"
          className={b('item')}
          color="primary"
          style={{backgroundColor: "#1976d2"}}
          onClick={() => onFilter(curFilters)}>Filter</Button>
    </Col>
  </Row>;
}

export default JobFilters;
