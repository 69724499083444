import React, { useCallback, useState } from 'react';
import b_ from 'b_';
import {Col, Form, InputNumber, Row, Select} from 'antd';
import RangeInput from '../../../Components/RangeInput';
import './styles.scss';
import {Button} from "@material-ui/core";

const b = b_.lock('AccountsFilters');

const BALANCE_LIMIT_TO_MIN = 0;
const BALANCE_LIMIT_TO_MAX = 10000;

const PURCHASE_LIMIT_TO_MIN = 0;
const PURCHASE_LIMIT_TO_MAX = 10000;

function AccountsFilters({ className, filters = {}, onFilter, accounts}) {
  const [curFilters, setCurFilters] = useState(filters);
  const onChangeBalLimit = useCallback(([balanceLimitFrom, balanceLimitTo]) => {
    setCurFilters({
      ...curFilters,
      balanceLimitFrom: balanceLimitFrom === BALANCE_LIMIT_TO_MIN ? null : balanceLimitFrom,
      balanceLimitTo: balanceLimitFrom === BALANCE_LIMIT_TO_MAX ? null : balanceLimitTo,
    });
  }, [setCurFilters, curFilters]);

  const onChangePurchaseLimit = useCallback(([purchaseLimitFrom, purchaseLimitTo]) => {
    setCurFilters({
      ...curFilters,
      purchaseLimitFrom: purchaseLimitFrom === PURCHASE_LIMIT_TO_MIN ? null : purchaseLimitFrom,
      purchaseLimitTo: purchaseLimitTo === PURCHASE_LIMIT_TO_MAX ? null : purchaseLimitTo,
    });
  }, [setCurFilters, curFilters]);

  const onChangeDriver = useCallback((driver) => {
    setCurFilters({
      ...curFilters,
      driver: driver,
    });
  }, [setCurFilters, curFilters]);

  const onChangeFavorites = useCallback((favorit) => {
    const group = JSON.parse(localStorage.getItem('accountsChosenResellM'))
    setCurFilters({
      ...curFilters,
        ids: group.groups[favorit] && group.groups[favorit].join(','),
        group:  favorit,
    });
  }, [setCurFilters, curFilters]);

  const onFilterClick = useCallback(() => {
    onFilter(curFilters);
  }, [curFilters, onFilter]);

  return (<Row gutter={16} justify={'space-between'} className={className}>
    <Col >
      <RangeInput label="Limit"
                  value={[curFilters.balanceLimitFrom, curFilters.balanceLimitTo]}
                  min={BALANCE_LIMIT_TO_MIN}
                  max={BALANCE_LIMIT_TO_MAX}
                  onChange={onChangeBalLimit}
      />
    </Col>
    <Col >
      <RangeInput label="Purchase Limit "
                  value={[curFilters.purchaseLimitFrom, curFilters.purchaseLimitTo]}
                  min={PURCHASE_LIMIT_TO_MIN}
                  max={PURCHASE_LIMIT_TO_MAX}
                  onChange={onChangePurchaseLimit}
      />
    </Col>
    <Col style={{flexDirection:'column'}} className={b('container')}>
      <b className={b('label')}>Driver</b>
      <InputNumber onChange={onChangeDriver} value={curFilters.driver} min={0} step={1} />
    </Col>
    <Col style={{flexDirection:'column'}}>
        <b className={b('label')} style={{textAlign: "center"}} >show favorites</b>
        <Select
                mode="multiple"
                onChange={onChangeFavorites}
                value={curFilters.group}
                className={className}
                style={{width: 300, borderRadius: "22px"}}
                allowClear>
            {   accounts.nameGroups.map( e => <Select.Option
                value={e}
                key={e}>{e}</Select.Option>)}
        </Select>
    </Col>
    <Col style={{flexDirection:'column'}} className={b('container')}>
        <b className={b('label')} />
      <Button
          variant="contained"
          size="small"
          color="primary"
          style={{ width: '100%', backgroundColor: "#1976d2"}}
          onClick={onFilterClick}>Filter</Button>
    </Col>
  </Row>);
}

export default AccountsFilters;
