import React, {useCallback, useEffect, useState} from 'react';
import ProductName from './ProductName';
import ProductType from './ProductType';
import TableSettings from "../../Accounts/AccountsTable/TableSettings";
import useStorageState from "../../../Utils/useStorageState";
import ResizeTable from "../../../Components/ResizeableTitle";

function ProductTable({ list, pagination, onChange, isLoading }) {
    const GetColumnTable = () => {
        return [
            {
                dataIndex: "id",
                title: "ID",
                width: 50,
            },

            {
                dataIndex: "name",
                title: "Name",
                width: 20,
                maxWidth: 60,
                render: (productName) => <ProductName productName={productName} />
            },
            {
                dataIndex:"sourceAll",
                title:"Source",
                width: 200,
                children : [
            {
                dataIndex: "source",
                title: "Type",
                width: 50,
                render: (type) => (
                    <ProductType productType={type} />
                )
            },
            {
                dataIndex: "sourceId",
                title: "Id",
                width: 50,
                render: (id) => (<a target="_blank"
                                    rel="noreferrer noopener"
                                    href={`https://www.amazon.com/dp/${id}`}
                >
                    {id}
                </a>)
            },
            {
                dataIndex: "sourcePrice",
                title: "Price",
                width: 50,
            }
        ]},
            {
                dataIndex: "targetAll",
                title: "Target",
                width: 200,
                children: [
                    {
                        dataIndex:"target",
                        title:"Type",
                        width: 50,
                        render: (type) => <ProductType productType={type} />
                        },
                    {
                        dataIndex: "targetId",
                        title: "Id",
                        width: 50,
                        render: (id) =>
                            <a target="_blank"
                               rel="noreferrer noopener"
                               href={`https://www.ebay.com/itm/${id}`}
                            >
                                {id}
                            </a>},
                    {
                        dataIndex: "targetPrice",
                        title: "Price",
                        width: 50,
                    }
                ]
            },
            {
                dataIndex:"qty",
                title: "Quantity",
                width: 50,
            }
        ]
    }

    const [Checkbox, setCheckbox] = useState([]);

    const [columns, setColumns] = useState(GetColumnTable());

    const [AllCheckbox, setAllCheckbox] = useStorageState("products", {
        checkedList: Checkbox,
        indeterminate: false,
        checkAll: true,
    });

    const onChangeCheckList = useCallback((checkedList) => {
        setAllCheckbox({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < columns.length,
            checkAll: checkedList.length === columns.length,
        });
    }, [Checkbox]);


    useEffect(() => {
        let allCheckList = []

        if(list.length && !Checkbox.length) {
            GetColumnTable().forEach((e) => {
                allCheckList.push( e[Object.keys(e)[1]])
                setCheckbox(allCheckList)
            })
        }
    }, [list, AllCheckbox, Checkbox.length])

    useEffect(() => {
        if(AllCheckbox.checkAll) {
            setAllCheckbox({
                checkedList: GetColumnTable().map(e => e.title),
                indeterminate: false,
                checkAll: true,
            })
        }
    },[AllCheckbox.checkAll, Checkbox.length, setAllCheckbox])

    const onChangeCheckAll = useCallback((e) => {
        setAllCheckbox({
            checkedList: (e.target.checked && columns.map(e => e.title)) || [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
    }, [Checkbox]);

    useEffect(() => {
        setColumns(GetColumnTable())
    }, [])

    const columnsFilter = columns.filter(e => AllCheckbox.checkedList.indexOf(e.title) !== -1);

    return <ResizeTable
        bordered
        trigger={"click"}
        scroll={{x: '800px'}}
        dataSource={list}
        rowKey="id"
        onChange={onChange}
        pagination={pagination}
        loading={isLoading}
        columns={columnsFilter}
        style={{ wordBreak: "break-word", wordWrap: "break-word" }}
        title={() => <TableSettings
            onChangeCheckAll={onChangeCheckAll}
            allChecks={AllCheckbox}
            Checkboxs={AllCheckbox.checkedList}
            TableColumn={GetColumnTable}
            setCheckbox={onChangeCheckList}
        />}
    />
}

export default ProductTable;
