import { createAction, createReducer } from '@reduxjs/toolkit';
import { getDefaultHandler, getDefaultState } from '../Utils/newReducerTools';
import InvoiceJobService from "../Services/InvoiceJobService";

export const fetchInvoiceJobs = createAction('invoiceJobs/list', (params) => ({
  payload: InvoiceJobService.getList(params),
}));

export const cancelInvoiceJobs = createAction('invoiceJobs/cancel', (ids) => ({
  payload: InvoiceJobService.cancel(ids),
}));

const initState = {
  list: getDefaultState(),
  cancel: getDefaultState()
};

export default createReducer(initState, {
  ...getDefaultHandler(fetchInvoiceJobs, 'list'),
  ...getDefaultHandler(cancelInvoiceJobs, 'cancel'),
});
