import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

const JOB_STATUS_TO_COLOR = {
  New: 'blue',
  Done: 'green',
  Error: 'red',
};

function PrintJobStatus({ className, jobStatus }) {
  return <Tag className={className} color={JOB_STATUS_TO_COLOR[jobStatus]}>
    {jobStatus.toLowerCase()}
  </Tag>;
}

PrintJobStatus.propTypes = {
  className: PropTypes.string,
  jobStatus: PropTypes.oneOf(['New', 'Done', 'Error']).isRequired,
};

PrintJobStatus.defaultProps = {
  className: '',
};

export default React.memo(PrintJobStatus);
