import React, { useCallback, useMemo } from 'react';
import b_ from 'b_';
import { Drawer, Form, Input, message } from 'antd';
import useBoolean from '../../../Utils/useBoolean';
import CountrySelector from '../Selectors/CountrySelector';
import CurrencySelector from '../Selectors/CurrencySelector';
import ClientSelector from '../Selectors/ClientSelector';
import TextArea from 'antd/lib/input/TextArea';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ErrorBlock from '../../../Components/ErrorBlock';
import { createAccount } from '../../../Reducers/accounts';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import {Button} from "@material-ui/core";
import {LoadingOutlined} from "@ant-design/icons";

const b = b_.lock('CreateAccount');

function CreateAccount({ className, afterSuccess }) {
  const dispatch = useDispatch();
  const aux = useSelector(state => state.accounts.aux.payload, shallowEqual);
  const isLoading = useSelector(state => state.accounts.create.isLoading, shallowEqual);

  const defaultValues = useMemo(() => ({
    balLimit: aux ? aux.limitDefault : undefined,
    purchLimit: aux ? aux.purchLimitDefault : undefined,
  }), [aux]);

  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);
  const handleSuccess = useCallback((data) => {
    dispatch(createAccount(data)).then(() => {
      close();
      afterSuccess();
      message.success('Account has been added!');
    });
  }, [close, afterSuccess, dispatch]);

  const error = useSelector(state => state.accounts.create.error, shallowEqual);

  return (
    <>
      <Button
          color="primary"
          variant="contained"
          size="medium"
          className={className}
          startIcon={<PlusOutlined />}
          style={{ backgroundColor: "#1976d2"}}
          onClick={open}
      >
        Create
      </Button>
      <Drawer visible={isOpen} title="Create Account" width={600} closable placement="right" onClose={close}>
        {error && <ErrorBlock message={error.message} />}
        <Form layout="vertical" className={b()} onFinish={handleSuccess}>
          <Form.Item
            rules={[
              { required: true, message: 'Please input account login.' },
            ]}
            label="Login"
            name="login"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please input password.' },
            ]}
            label="Password"
            name="password"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please input token.' },
            ]}
            label="Token"
            name="authToken"
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please select country.' },
            ]}
            label="Country:"
            name="country"
          >
            <CountrySelector />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please select currency.' },
            ]}
            label="Currency:"
            name="currency"
          >
            <CurrencySelector />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please input limit.' },
            ]}
            label="Limit:"
            name="balLimit"
            initialValue={defaultValues.balLimit}
            normalize={v => +v}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please input purchase limit.' },
            ]}
            label="Purchase Limit:"
            initialValue={defaultValues.purchLimit}
            name="purchLimit"
            normalize={v => +v}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please input proxy.' },
            ]}
            label="Proxy:"
            name="proxy"
          >
            <Input placeholder="host:port"/>
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please select client owned account' },
            ]}
            label="Client:"
            name="client"
          >
             <ClientSelector />
          </Form.Item>
          <Form.Item
            label="Comment:"
            name="comment"
          >
            <TextArea />
          </Form.Item>
          <Form.Item>
            <Button
                color="primary"
                variant="contained"
                startIcon={isLoading && <LoadingOutlined />}
                style={{ backgroundColor: "#1976d2"}}
                type="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default CreateAccount;
