import React, { useCallback } from 'react';
import useBoolean from '../../../Utils/useBoolean';
import { Drawer, Form, Input } from 'antd';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import ErrorBlock from '../../../Components/ErrorBlock';
import TextArea from 'antd/lib/input/TextArea';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { createProduct } from '../../../Reducers/products';
import {Button} from "@material-ui/core";
import {LoadingOutlined} from "@ant-design/icons";

function AddProduct({ className, afterSuccess }) {
  const dispatch = useDispatch();
  const { value: isOpen, setTrue: open, setFalse: close } = useBoolean(false);
  const { isLoading, error } = useSelector(state => state.products.create, shallowEqual);

  const handleSuccess = useCallback(({ amazonId, ebayId, ...deliveryAddress}) => {
    dispatch(createProduct({ item: { amazonId, ebayId }, deliveryAddress})).then(() => {
      close();
      afterSuccess();
    });
  }, [dispatch, afterSuccess, close]);

  return (
    <>
      <Button
          color="primary"
          variant="contained"
          size="large"
          style={{ backgroundColor: "#1976d2"}}
          className={className}
          startIcon={<PlusOutlined />}
          onClick={open}
      >
        Create
      </Button>
      <Drawer visible={isOpen} title="Create Account" width={600} closable placement="right" onClose={close}>
        {error && <ErrorBlock message={error.message} />}
        <Form layout="vertical" onFinish={handleSuccess}>
          <Form.Item
            rules={[
              { required: true, message: 'Please input amazon id.' },
            ]}
            label="Amazon Id"
            name="amazonId"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please input ebay id.' },
            ]}
            label="Ebay Id"
            name="ebayId"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please input full name.' },
            ]}
            label="Full Name"
            name="fullName"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please input address line 1.' },
            ]}
            label="Address Line 1"
            name="addressLine1"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            label="Address Line 2"
            name="addressLine2"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please input city.' },
            ]}
            label="City"
            name="city"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please input State or Region.' },
            ]}
            label="State or Region"
            name="stateOrRegion"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please input Postal Code.' },
            ]}
            label="Postal Code"
            name="postalCode"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please input Country Code.' },
            ]}
            label="Country Code"
            name="countryCode"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please input Phone Number.' },
            ]}
            label="Phone Number"
            name="phoneNumber"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please input Instructions.' },
            ]}
            label="Instructions"
            name="instructions"
          >
            <TextArea rows={3} />
          </Form.Item>
          <Form.Item>
            <Button
                color="primary"
                variant="contained"
                startIcon={isLoading && <LoadingOutlined />}
                style={{ backgroundColor: "#1976d2"}}
                type="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default AddProduct;
