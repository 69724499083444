import React, { useEffect } from 'react';
import { Select } from 'antd';
import { fetchConfigs } from '../../Reducers/config';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

function GroupSelector({ className, value, onChange }) {
  const groups = useSelector(
    (state) => {
      if (!state.config.data.payload) return null;

      try {
        let countryFilters = state.config.data.payload.find(({ name }) => name === 'countries');
        return countryFilters ? JSON.parse(countryFilters.value) : null;
      } catch (e) {
        return null;
      }
    },
    shallowEqual,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!groups) {
      dispatch(fetchConfigs());
    }
  }, []);

  return (
    <Select
      className={className}
      value={value}
      onChange={onChange}
      style={{ width: '100%' }}
      allowClear
    >
      {groups && groups.map(group => <Select.Option key={group} value={group}>{group}</Select.Option>)}
    </Select>);
}

export default GroupSelector;
