import React from 'react';
import { Select } from 'antd';

const ENABLED_JOBS = JSON.parse(process.env.REACT_APP_ENABLED_JOBS);

function SelectEnabledJobs({ onChange, value, disabled }) {

  return (
    <Select onChange={onChange}
            value={value}
            mode="multiple"
            disabled={disabled}
            style={{ width: '100%', minWidth: 250 }}
            placeholder="Please select"
    >
      {ENABLED_JOBS.map(
        (job) => <Select.Option value={job}>{job}</Select.Option>
      )}
    </Select>
  );
}

export default SelectEnabledJobs;
