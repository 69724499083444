import React, {useCallback, useEffect, useState} from 'react';
import { Input, message, Select } from 'antd';
import b_ from 'b_';
import PrintAccountState from '../../../Components/Prints/PrintAccountStatus';
import PrintPrice from '../../../Components/Prints/PrintPrice';
import EditableField from '../../../Components/EditableField';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {
    changeStatusAccount,
    updateBalLimitAccount,
    updateCommentAccount,
    updateCountryAccount,
    updateDriverAccount,
    updateClientAccount,
    updateEnabledJobsAccount,
    updateLoginAccount,
    updateProxyAccount,
    updatePurchLimitAccount, changeCurrency
} from '../../../Reducers/accounts';
import TextArea from 'antd/lib/input/TextArea';
import ChangePassword from '../ChangePassword';
import './styles.scss';
import {Link} from 'react-router-dom';
import CountrySelector from '../Selectors/CountrySelector';
import ChangeToken from '../ChangeToken';
import Expanded from './Expanded';
import EnabledJobs from '../../../Components/EnabledJobs';
import EnableWithheld from '../EnableWithheld';
import EnablePending from '../EnablePending';
import ClientSelector from "../Selectors/ClientSelector";
import TableSettings from "./TableSettings";
import useStorageState from "../../../Utils/useStorageState";
import ResizeTable from "../../../Components/ResizeableTitle";
import AccountsChosen from "../AccountsChosen";
import AccountGroups from "../AccountsGroups";
import CurrencySelector from "../../../Components/CurrencySelector";

const b = b_.lock('AccountsTable');

function AccountsTable({
                         list,
                         pagination,
                         onChange,
                         isLoading,
                         afterUpdate,
                         selected,
                         setSelected,
                         onFilter,
                         filters,
                         sorter,
                         accountsLocal,
                         addAccountChosen,
                         removeAccountChosen,
                         removeAccountFromGroup
                       }) {
  const dispatch = useDispatch();

  const loadings = useSelector(state => ({
    proxy: state.accounts.updateProxy.isLoading,
    driver: state.accounts.updateDriver.isLoading,
    comment: state.accounts.updateComment.isLoading,
    status: state.accounts.changeStatus.isLoading,
    login: state.accounts.updateLogin.isLoading,
    balLimit: state.accounts.updateBalLimit.isLoading,
    purchLimit: state.accounts.updatePurchLimit.isLoading,
    country: state.accounts.updateCountry.isLoading,
    currency: state.accounts.updateCurrency.isLoading,
    client: state.accounts.updateClient.isLoading,
    enabledJobs: state.accounts.updateEnabledJobs.isLoading,
  }), shallowEqual);

  const {payload: clients} = useSelector(state => state.clients.list)

  const clientFilters = useSelector(state => state.clients.list.payload || null)
  const {accountCodeFilters, countryFilters} = useSelector(
    (state) => {
      if (!state.config.data.payload) return {accountCodeFilters: null};

      try {
        let accountCodeFilters = state.config.data.payload.find(({name}) => name === 'accountResultCodeFilter');
        accountCodeFilters = accountCodeFilters ? JSON.parse(accountCodeFilters.value) : null;

        let countryFilters = state.config.data.payload.find(({name}) => name === 'countries');
        countryFilters = accountCodeFilters ? JSON.parse(countryFilters.value) : null;

        return {accountCodeFilters, countryFilters};
      } catch (e) {
        return {accountCodeFilters: null, countryFilters: null};
      }
    },
    shallowEqual
  );

  const updateEnabledJobs = useCallback((id, enabledJobs) => {
    dispatch(updateEnabledJobsAccount({id, enabledJobs})).then(() => {
      message.success('Enabled Jobs has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update Enabled Jobs.');
    });
  }, [dispatch, afterUpdate]);

  const updateCountry = useCallback((id, country) => {
    dispatch(updateCountryAccount({id, country})).then(() => {
      message.success('Country has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update country.');
    });
  }, [dispatch, afterUpdate]);

  const updatePurchLimit = useCallback((id, purchLimit) => {
    dispatch(updatePurchLimitAccount({id, purchLimit: +purchLimit})).then(() => {
      message.success('Purch Limit has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update purch limit');
    });
  }, [dispatch, afterUpdate]);

  const updateBalLimit = useCallback((id, balLimit) => {
    dispatch(updateBalLimitAccount({id, balLimit: +balLimit})).then(() => {
      message.success('Bal Limit has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update bal limit');
    });
  }, [dispatch, afterUpdate]);

  const updateLogin = useCallback((id, login) => {
    dispatch(updateLoginAccount({id, login})).then(() => {
      message.success('Login has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update login');
    });
  }, [dispatch, afterUpdate]);

  const updateProxy = useCallback((id, proxy) => {
    dispatch(updateProxyAccount({id, proxy})).then(() => {
      message.success('Proxy has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update proxy');
    });
  }, [dispatch, afterUpdate]);

  const updateDriver = useCallback((id, driver) => {
    dispatch(updateDriverAccount({id, driver})).then(() => {
      message.success('Driver has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update driver');
    });
  }, [dispatch, afterUpdate]);

  const updateClient = useCallback((id, client) => {
    dispatch(updateClientAccount({id, client})).then(() => {
      message.success('Client has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update client.');
    });
  }, [dispatch, afterUpdate]);

  const updateComment = useCallback((id, comment) => {
    dispatch(updateCommentAccount({id, comment})).then(() => {
      message.success('Comment has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update comment');
    });
  }, [dispatch, afterUpdate]);

  const updateStatus = useCallback((id, status) => {
    dispatch(changeStatusAccount({id, action: status.toLowerCase().slice(0, -1)})).then(() => {
      message.success('Status has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update status');
    });
  }, [dispatch, afterUpdate]);

    const updateCurrency = useCallback((id, currency) => {
        dispatch(changeCurrency({id, currency})).then(() => {
            message.success('Currency has been updated!');
            afterUpdate();
        }).catch(error => {
            console.log(error);
            message.error('Can\'t update currency');
        });
    }, [dispatch, afterUpdate]);

    const [Checkboxs, setCheckbox] = useState([]);

    const [AllCheckbox, setAllCheckbox] = useStorageState('columnsAccountsTable', {
        checkedList: Checkboxs,
        indeterminate: false,
        checkAll: true,
    });

    const onChangeCheckList = useCallback((checkedList) => {
        setAllCheckbox({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < columns.length,
            checkAll: checkedList.length === columns.length,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Checkboxs]);

const GetColumnTable = (sorter,filterMultiple) => {
    return [
        {
            dataIndex:"id",
            title:"ID",
            align: "center",
            defaultView: true,
            width: 59,
            sorter,
            filterMultiple,
            sortOrder:sorter.field === 'id' ? sorter.order : null,
            render:id => <Link to={`account/${id}`}>{id}</Link>,
        },
        {
            dataIndex:"login",
            title:"Login",
            align: "center",
            defaultView: true,
            width: 272,
            render:(login, rec) => (
                <EditableField handleSave={(newLogin) => updateLogin(rec.id, newLogin)}
                       title="Update Login"
                       withTitle
                       isLoading={loadings.login || isLoading}
                       iconClassName={b('edit')}
                       titlePopover="Edit Login"
                       initialValue={login}
                       changeBlock={({onChange, ...props}) => (
                           <Input {...props}
                                  size="small"
                                  onChange={e => onChange(e.target.value)}/>
                       )}>
                    {login}
                </EditableField>)
        },
        {
            dataIndex: "status",
            title: "Status",
            align: "center",
            defaultView: true,
            width: 111,
            sorter,
            sortOrder: sorter.field === 'status' ? sorter.order : null,
            filters: [
                {text: 'Enabled', value: 'Enabled'},
                {text: 'Disabled', value: 'Disabled'},
                {text: 'TempInvalid', value: 'TempInvalid'},
                {text: 'Invalid', value: 'Invalid'},
            ],
            filterMultiple,
            filteredValue: filters['status'] || null,
            render: (status, rec) => (
                <>
                    <PrintAccountState
                        status={status}
                        rec={rec}
                        handleSave={updateStatus}
                    />
                    <EditableField 
                            handleSave={(newStatus) => updateStatus(rec.id, newStatus)}
                            title="Update Status"
                            withTitle
                            titlePopover="Edit Status"
                            isLoading={loadings.status || isLoading}
                            iconClassName={b('edit')}
                            initialValue={status}
                            changeBlock={(props) => (
                              <Select {...props} size="small">
                                <Select.Option value="Disable">Disable</Select.Option>
                                <Select.Option value="Enable">Enable</Select.Option>
                              </Select>
                            )} />
                </>
            )
        },
        {
            dataIndex:"enabledJobs",
            title:"Enabled Jobs",
            align: "center",
            defaultView: true,
            width: 175,
            render:(enabledJobs, rec) => (
                <EnabledJobs isLoading={loadings.enabledJobs || isLoading}
                     enabledJobs={enabledJobs}
                     iconClassName={b('edit')}
                     handleSave={(newStatus) => updateEnabledJobs(rec.id, newStatus)}
                />)
        },
        {
            dataIndex:"clientId",
            title:"Client",
            align: "center",
            defaultView: true,
            width: 107,
            sorter,
            sortOrder:sorter.field === 'clientId' ? sorter.order : null,
            ...(clientFilters ? {
                filteredValue: filters['clientId'] || null,
                filters: clientFilters.map(client => ({value: client.id, text: client.name})),
                filterMultiple: true,
            } : {}),
            render:(value, rec) =>
                <EditableField handleSave={(newValue) => updateClient(rec.id, newValue)}
                   title="Update Client"
                   titlePopover="Edit Client"
                   iconClassName={b('edit')}
                   withTitle
                   isLoading={loadings.client || isLoading}
                   initialValue={value}
                   changeBlock={(props) => (
                       <ClientSelector {...props}
                                       size="small"
                                       className="w100"
                       />
                   )}>
                    {(clients?.find(client => client.id === value)?.name) || 'Unknown'}
                </EditableField>
        },
        {
            dataIndex:"country",
            title:"Country",
            align: "center",
            defaultView: true,
            width: 122,
            sorter,
            sortOrder:sorter.field === 'country' ? sorter.order : null,
            ...(countryFilters ? {
                filteredValue: filters['country'] || null,
                filters: countryFilters.map(item => ({value: item, text: item})),
                filterMultiple: true,
            } : {}),
            render:(value, rec) =>
              <EditableField 
                    handleSave={(newValue) => updateCountry(rec.id, newValue)}
                    title="Update Country"
                    titlePopover="Edit Country"
                    iconClassName={b('edit')}
                    withTitle
                    isLoading={loadings.country || isLoading}
                    initialValue={value}
                    changeBlock={(props) => (
                        <CountrySelector {...props}
                                          size="small"
                                          className="w100"
                        />
                )}>
                {value}
              </EditableField>
        },
        {
            dataIndex: "currency",
            title: "Currency",
            align: "center",
            defaultView: true,
            width: 105,
            sorter,
            sortOrder: sorter.field === 'currency' ? sorter.order : null,
            render: (value, rec) => (<EditableField handleSave={(newValue) => updateCurrency(rec.id, newValue)}
                                                    title="Update Country"
                                                    titlePopover="Edit Country"
                                                    iconClassName={b('edit')}
                                                    withTitle
                                                    isLoading={loadings.currency || isLoading}
                                                    initialValue={value}
                                                    changeBlock={(props) => (
                                                        <CurrencySelector {...props} value={value} />
                                                    )}>
                {value}
            </EditableField>)
        },
        {
            dataIndex:"balance",
            title:"Balance",
            defaultView: true,
            align:"center",
            width: 97,
            sorter,
            sortOrder:sorter.field === 'balance' ? sorter.order : null,
            render:value => <PrintPrice value={value}/>,
        },
        {
            dataIndex:"balLimit",
            title:"Limit",
            defaultView: true,
            align:"center",
            width: 77,
            sorter,
            sortOrder:sorter.field === 'balLimit' ? sorter.order : null,
            render:(value, rec) => (
                <EditableField handleSave={(newValue) => updateBalLimit(rec.id, newValue)}
                   title="Update BalLimit"
                   titlePopover="Edit BalLimit"
                   iconClassName={b('edit')}
                   withTitle
                   isLoading={loadings.balLimit || isLoading}
                   initialValue={value}
                   changeBlock={({onChange, ...props}) => (
                       <Input {...props}
                              size="small"
                              onChange={e => onChange(e.target.value)}/>
                   )}>
                    <PrintPrice value={value}/>
                </EditableField>)
        },
        {
            dataIndex:"purchLimit",
            title:"Purchase Limit",
            defaultView: true,
            width: 142,
            align:"center",
            sorter,
            sortOrder:sorter.field === 'purchLimit' ? sorter.order : null,
            render:(value, rec) =>(
                <EditableField handleSave={(newValue) => updatePurchLimit(rec.id, newValue)}
                   title="Update PurchLimit"
                   iconClassName={b('edit')}
                   titlePopover="Edit PurchLimit"
                   withTitle
                   isLoading={loadings.purchLimit || isLoading}
                   initialValue={value}
                   changeBlock={({onChange, ...props}) => (
                       <Input {...props}
                              size="small"
                              onChange={e => onChange(e.target.value)}/>
                   )}>
                    <PrintPrice value={value}/>
                </EditableField>)
        },
        {
            dataIndex:"proxy",
            title:"Proxy",
            align: "center",
            defaultView: true,
            width: 336,
            sorter,
            sortOrder:sorter.field === 'proxy' ? sorter.order : null,
            render:(value, rec) =>(
                <EditableField handleSave={(newProxy) => updateProxy(rec.id, newProxy)}
                   title="Update Proxy"
                   titlePopover="Edit Proxy"
                   iconClassName={b('edit')}
                   withTitle
                   isLoading={loadings.proxy || isLoading}
                   initialValue={value}
                   changeBlock={({onChange, ...props}) => (
                       <Input {...props}
                              placeholder="host:port"
                              size="small"
                              onChange={e => onChange(e.target.value)}/>
                   )}>
                    {value}
                </EditableField>)
        },
        {
            dataIndex:"driver",
            title:"Driver",
            defaultView: true,
            width: 100,
            align:"center",
            sorter,
            sortOrder:sorter.field === 'driver' ? sorter.order : null,
            render:(value, rec) =>(
                <EditableField handleSave={(newDriver) => updateDriver(rec.id, newDriver)}
                   title="Update Driver"
                   titlePopover="Edit Driver"
                   isLoading={loadings.driver || isLoading}
                   iconClassName={b('edit')}
                   withTitle
                   initialValue={value}
                   changeBlock={({onChange, ...props}) => (
                       <Input {...props}
                              size="small"
                              type="number"
                              onChange={e => onChange(e.target.value)}/>
                   )}>
                    {value}
                </EditableField>)
        },
        {
            dataIndex:"comment",
            title:"Comment",
            align: "center",
            defaultView: true,
            width: 212,
            sorter,
            sortOrder:sorter.field === 'comment' ? sorter.order : null,
            render:(value, rec) =>
                <EditableField handleSave={(newComment) => updateComment(rec.id, newComment)}
                   title="Update Comment"
                   titlePopover="Edit Comment"
                   isLoading={loadings.comment || isLoading}
                   iconClassName={b('edit')}
                   withTitle
                   initialValue={value}
                   changeBlock={({onChange, ...props}) => (
                       <TextArea {...props}
                                 size="small"
                                 onChange={e => onChange(e.target.value)}/>
                   )}>
                    {value}
                </EditableField>
        },
        {
            dataIndex:"resultCode",
            title:"Result Code",
            align: "center",
            defaultView: true,
            width: 148,
            sorter,
            sortOrder:sorter.field === 'resultCode' ? sorter.order : null,
            ...(accountCodeFilters ? {
                filteredValue: filters['resultCode'] || null,
                filters: accountCodeFilters.map(item => ({value: item, text: item})),
                filterMultiple: true,
            } : {})
        },
        {
            dataIndex:"id",
            title:"Actions",
            align: "center",
            defaultView: true,
            width: 109,
            render:(id, rec) => (<div className={b('actions')}>
                <div className={b('icon')}>
                    <AccountsChosen accountId={id}
                                    accounts={accountsLocal.groups}
                                    groupAccounts={accountsLocal.nameGroups}
                                    addAccountChosen={addAccountChosen}
                                    removeAccountChosen={removeAccountChosen}
                    />
                    <AccountGroups accountId={id} removeAccountFromGroup={removeAccountFromGroup} accounts={accountsLocal}/>
                </div>
                <div className={b('icon')}>
                    <ChangePassword className="mr-small" accountId={id}/>
                    <ChangeToken token={rec.authToken} accountId={id}/>
                </div>
                <div className={b('icon')}>
                    <EnablePending accountId={id} afterSuccess={afterUpdate}/>
                    <EnableWithheld accountId={id} afterSuccess={afterUpdate}/>
                </div>
            </div>)
        },
    ];
}

const [columns, setColumns] = useState(GetColumnTable(sorter,true));

    useEffect(() => {
        let allCheckList = []

        if(list.length && !Checkboxs.length) {
            GetColumnTable(sorter,true).forEach((e) => {
                allCheckList.push( e[Object.keys(e)[1]])
                setCheckbox(allCheckList)
                return;
            })
        }

    }, [list, AllCheckbox])

    useEffect(() => {
        if(AllCheckbox.checkAll) {
            setAllCheckbox({
                checkedList:GetColumnTable(sorter,true).map(e => e.title),
                indeterminate: false,
                checkAll: true,
            })
        }
    },[AllCheckbox.checkAll])

    const onChangeCheckAll = useCallback((e) => {
        setAllCheckbox({
            checkedList: (e.target.checked && columns.map(e => e.title)) || [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Checkboxs]);

    useEffect(() => {
        setColumns(GetColumnTable(sorter,true))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sorter, accountsLocal, filters, clients, countryFilters?.length, isLoading])
    
    const columnsFilter = columns.filter(e => AllCheckbox.checkedList.indexOf(e.title) !== -1);
    const loader = Object.keys(loadings).some(e => !!loadings[e]) || isLoading

    return <ResizeTable
                bordered
                className={b()}
                dataSource={list}
                scroll={{ y: "calc(100vh - 125px)", x: "max-content" }}
                size="middle"
                onChange={onChange}
                pagination={pagination}
                loading={loader}
                onFilter={onFilter}
                columns={columnsFilter}
                rowClassName={() => b('lock')}
                rowKey="id"
                rowSelection={{
                  selectedRowKeys: selected,
                  onChange: setSelected,
                }}
                title={() => <TableSettings
                    onChangeCheckAll={onChangeCheckAll}
                    allChecks={AllCheckbox}
                    Checkboxs={AllCheckbox.checkedList}
                    TableColumn={() =>GetColumnTable(sorter,true)}
                    setCheckbox={onChangeCheckList}
                />}
                expandable={{
                  expandedRowRender: record => <Expanded record={record}/>,
                }}
  >
  </ResizeTable>;
}

export default AccountsTable;
