import React from 'react';
import { Select } from 'antd';
import ErrorBlock from '../ErrorBlock';

const REACT_APP_DRIVERS_ENGINE_ID = process.env.REACT_APP_DRIVERS_ENGINE_ID;
let parsedEngineType = null;

try {
  parsedEngineType = JSON.parse(REACT_APP_DRIVERS_ENGINE_ID);
} catch (e) {
  console.log('parsedEngineType e:', e);
}

function DriversEngineSelector({ value, onChange, className, placeholder, defaultValue, disabled }) {
  if (!parsedEngineType) {
    return <ErrorBlock message="ENV REACT_APP_DRIVERS_ENGINE_ID is not set!" />
  }

  return (<Select defaultValue={defaultValue}
                  placeholder={placeholder}
                  className={className}
                  allowClear
                  value={value}
                  onChange={onChange}
                  style={{ width: '100%' }}
                  disabled={disabled}
  >
    {parsedEngineType.map(engine => <Select.Option value={engine}>{engine}</Select.Option>)}
  </Select>)
}

export default DriversEngineSelector;
