import ApiService from './ApiService';

export default {
  getList({ filters, orderPath, ...params }) {
    const updatedParams = { ...params };

    if (filters && Object.keys(filters).length) {
      for (let key in filters) {
        if (key === 'Dates') {
          updatedParams.fromTs = filters[key][0];
          updatedParams.toTs = filters[key][1];
        } else {
          updatedParams[key] = filters[key].join(',');
        }
      }
    }

    if (orderPath) {
      updatedParams.order = orderPath.replace('end', '');
    }

    return ApiService.get('/jobs', { ...updatedParams, jobType: 'Purchase' });
  },
  cancel(jobIds) {
    return ApiService.post('/job/cancel', { jobIds });
  },
  sendOrder(data) {
    return ApiService.sendFormData('/purchase', data);
  },
  downloadInvoices(jobIds) {
    return ApiService.post('/purchase/invoices', { jobIds });
  },
  readTracking(jobIds) {
    return ApiService.post('/purchase/tracking', { jobIds });
  }
};
