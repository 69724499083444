import React, { useCallback } from 'react';
import {message, Popconfirm, Popover} from 'antd';
import AccountService from '../../../Services/AccountService';
import useDefaultRequestReducer from '../../../Utils/useDefaultRequestReducer';
import {Button, Link} from "@material-ui/core";
import {ClockCircleOutlined, KeyOutlined, LoadingOutlined} from "@ant-design/icons";

function EnablePending({ className, accountId, afterSuccess }) {
  const { state: { isLoading }, fetch } = useDefaultRequestReducer(AccountService.enablePending);

  const update = useCallback(() => {
    fetch({ id: accountId }).then(() => {
      message.success('Success enable pending!');
      afterSuccess();
    }).catch(() => {
      message.error('Something went wrong!');
    });
  }, [accountId, fetch, afterSuccess]);

  return (
    <Popconfirm placement="topLeft"
                title={`Are you sure enable pending?`}
                onConfirm={update}
                okText="Yes"
                cancelText="No"
    >
        <Popover placement={"bottomRight"} content={"enable pending"}>
            {isLoading ? <LoadingOutlined /> : <ClockCircleOutlined />}
        </Popover>
    </Popconfirm>
  );
}

export default EnablePending;
