import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';

function CurrencySelector({ className, value, defaultValue, onChange, disabled }) {
  const { payload, isLoading } = useSelector(state => state.accounts.aux, shallowEqual);

  return (
    <Select defaultValue={defaultValue}
            className={className}
            loading={isLoading}
            allowClear
            value={value}
            onChange={onChange}
            style={{ width: '100%' }}
            disabled={disabled}
    >
      {payload && payload.currencies.map(value => <Select.Option value={value}>{value}</Select.Option>)}
    </Select>
  )
}

CurrencySelector.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CurrencySelector;
