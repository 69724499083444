import ApiService from './ApiService';

export default {
  getList() {
    return ApiService.get('/purchases');
  },
  createProduct(data) {
    return ApiService.post('/purchases', data);
  },
};
