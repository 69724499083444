import React, {useCallback} from 'react';
import {Drawer, Form, Popover, Select} from 'antd';
import useBoolean from '../../../Utils/useBoolean';
import { useDispatch } from 'react-redux';
import {Button, Link} from "@material-ui/core";
import {UserSwitchOutlined} from "@ant-design/icons";

function AccountGroups({ accountId, accounts, removeAccountFromGroup }) {

    const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);
    const dispatch = useDispatch();

    const handleSuccess = useCallback(({ titleGroup }) => {
        removeAccountFromGroup(accountId, titleGroup)
        close();
    }, [dispatch, accountId, close]);

    const objKey = accounts.nameGroups.filter(e => accounts.groups[e].indexOf(accountId) !== -1 )
    return <>
            <Popover placement="topLeft" content={"Change account groups"}>
                <UserSwitchOutlined onClick={open} />
            </Popover>
        <Drawer onClose={close}
                visible={isOpen}
                title="Change account groups"
                placement="right"
                width={600}
        >
            <Form layout="vertical" onFinish={handleSuccess}>
                <Form.Item
                    rules={[
                        { required: false},
                    ]}
                    name="titleGroup"
                >
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Please select"
                        value={accounts.nameGroups}
                        defaultValue={objKey}
                    >
                            {accounts.nameGroups.map(e =>  <Select.Option
                                value={e}
                                key={e}>{e}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button variant="contained"
                            style={{ backgroundColor: "#1976d2"}}
                            color="primary"
                            type="submit">
                        change account groups
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    </>;
};

export default AccountGroups;
