import React, { useCallback, useState } from 'react';
import b_ from 'b_';
import { message } from 'antd';
import Title from 'antd/lib/typography/Title';
import useTableState from '../../Utils/useTableState';
import { cancelRedeemJobs, fetchRedeemJobs } from '../../Reducers/redeemJobs';
import JobsTable from '../../Components/JobsTable';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { CloseCircleOutlined } from "@ant-design/icons/lib/icons";
import JobFilters from '../../Components/JobFilters';
import './styles.scss';
import {Button} from "@material-ui/core";
import {LoadingOutlined} from "@ant-design/icons";

const b = b_.lock('RedeemJob');

function RedeemJob() {
  const { fetch, onFilter, ...props } = useTableState(state => state.redeemJobs.list, fetchRedeemJobs);
  const [selectedRow, setSelectedRow] = useState([]);
  const dispatch = useDispatch();

  const cancelLoading = useSelector(state => state.redeemJobs.cancel.isLoading, shallowEqual);

  const cancelJobs = useCallback(() => {
    dispatch(cancelRedeemJobs(selectedRow)).then(() => {
      message.success('Jobs has been canceled!');
      setSelectedRow([]);
      fetch();
    });
  }, [dispatch, selectedRow, fetch, setSelectedRow]);

  return <div className={b()}>
    <Title className={`mb ${b('title')}`}>
      Redeem Jobs
    </Title>
    <div className={`mb ${b('buttons')}`}>
      <Button
          onClick={cancelJobs}
          disabled={!selectedRow.length}
          startIcon={props.isLoading || cancelLoading ? <LoadingOutlined /> : <CloseCircleOutlined />}
          size="large"
          color="secondary"
          variant="contained"
      >
        Cancel Jobs
      </Button>
    </div>
    <JobFilters filters={props.filters} onFilter={onFilter} className="mb" />
    <JobsTable {...props} nameLocalStore="redeemJobs" selected={selectedRow} setSelected={setSelectedRow} />
  </div>;
}

export default RedeemJob;
