import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

const STATE_TO_COLOR = {
  Enabled: 'green',
  Disabled: 'gray',
  Blocked: 'red',
};

function PrintDriverStatus({ className, status }) {
  return <Tag className={className} color={STATE_TO_COLOR[status]}>{status}</Tag>;
}

PrintDriverStatus.propTypes = {
  className: PropTypes.string,
  status: PropTypes.oneOf(Object.keys(STATE_TO_COLOR)).isRequired,
};

PrintDriverStatus.defaultProps = {
  className: '',
};

export default PrintDriverStatus;
