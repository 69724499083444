import React, {useCallback, useState} from 'react';
import {Drawer, Form, Input, Popover} from 'antd';
import useBoolean from '../../../Utils/useBoolean';
import ErrorBlock from '../../../Components/ErrorBlock';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {Button, Link} from "@material-ui/core";
import {UsergroupAddOutlined} from "@ant-design/icons";

function AccountsChosen({ accountId,  addAccountChosen, accounts, removeAccountChosen, className }) {

    const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);
    const dispatch = useDispatch();
    const [removeGroup, setRemoveGroup] = useState("");

    const handleSuccess = useCallback(({ titleGroup, ...p }) => {
        addAccountChosen(accountId, titleGroup)
        close();
    }, [dispatch, accountId, close]);

    const handleRemove = useCallback(() => {
        removeAccountChosen(accountId, removeGroup)
        close();
    }, [dispatch, accountId, close, removeGroup]);

    return <>
        <Popover content={"add/delete group"}>
            <UsergroupAddOutlined onClick={open}/>
        </Popover>
        <Drawer onClose={close}
                visible={isOpen}
                title="Change group"
                placement="right"
                width={600}
        >
            <Form layout="vertical" onChange={(e) => setRemoveGroup(e.target.value)} onFinish={handleSuccess}>
                <Form.Item
                    rules={[
                        { required: true, message: 'Please input change.' },
                    ]}
                    name="titleGroup"
                >
                    <Input type="text" />
                </Form.Item>
                <Form.Item>
                    <Button
                        style={{ backgroundColor: "#1976d2"}}
                        color="primary"
                        type="submit"
                        variant="contained">
                        add account to group
                    </Button>
                </Form.Item>
            </Form>
            <Button
                type="link"
                onClick={handleRemove}
                variant="contained"
                color="secondary"
                style={{
                    textDecoration: 'none',
                    position: 'absolute',
                    left: 255,
                    top: 135,
                }}>
                delete Group
            </Button>
        </Drawer>
    </>;
};

export default AccountsChosen;
