import { createAction, createReducer } from '@reduxjs/toolkit';
import ClientService from '../Services/ClientService';
import { getDefaultHandler, getDefaultState } from '../Utils/newReducerTools';

export const fetchClientsList = createAction('clients/getList', () => ({
  payload: ClientService.getList(),
}));

export const createClient = createAction('clients/create', (data) => ({
  payload: ClientService.createClient(data),
}));

const initState = {
  list: getDefaultState(),
  create: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(fetchClientsList, 'list'),
  ...getDefaultHandler(createClient, 'create'),
});
