import React, {useState} from 'react'
import pathToFileName from '../../Utils/pathToName';
import {Button} from "@material-ui/core";
import {DownOutlined, GlobalOutlined, RightOutlined} from "@ant-design/icons";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;


function PageScreens({className, pages}) {
  const [isOpen, setIsOpen] = useState(false);
  const [buttonText, setButtonText] = useState(`Show (${pages.length})`);

  const handleClick = () => {
    setIsOpen(!isOpen)
    if (!isOpen)
      setButtonText(`Hide (${pages.length})`);
    else
      setButtonText(`Show (${pages.length})`);
  }

  return <>
    <div className="container">
      <Button
          className={className}
          size="small"
          color="primary"
          style={{backgroundColor: "#00afff"}}
          variant="contained"
          startIcon={isOpen ? <DownOutlined /> : <RightOutlined />}
          onClick={() => handleClick()}
          disabled={!pages.length}>
        {buttonText}
      </Button>

      {isOpen ? (
        <div style={{textAlign: "center"}}>
          {pages.map(page =>
            <a className="mb-small block" href={BACKEND_URL + page} rel="noreferrer noopener" target="_blank">
              {pathToFileName(page)}
            </a>)}
        </div>) : null
      }
    </div>
  </>;
}

export default PageScreens;
