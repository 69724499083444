import React, { useCallback, useState } from 'react';
import b_ from 'b_';
import { message } from 'antd';
import Title from 'antd/lib/typography/Title';
import useTableState from '../../Utils/useTableState';
import JobsTable from '../../Components/JobsTable';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import JobFilters from '../../Components/JobFilters';
import { cancelOtherJobs, fetchOtherJobs } from '../../Reducers/otherJobs';
import './styles.scss';
import {Button} from "@material-ui/core";
import {LoadingOutlined} from "@ant-design/icons";
import {CloseCircleOutlined} from "@ant-design/icons/lib/icons";

const b = b_.lock('OtherJob');

function OtherJob() {
  const { fetch, onFilter, ...props } = useTableState(state => state.otherJobs.list, fetchOtherJobs);
  const [selectedRow, setSelectedRow] = useState([]);
  const dispatch = useDispatch();

  const cancelLoading = useSelector(state => state.otherJobs.cancel.isLoading, shallowEqual);

  const cancelJobs = useCallback(() => {
    dispatch(cancelOtherJobs(selectedRow)).then(() => {
      message.success('Jobs has been canceled!');
      setSelectedRow([]);
      fetch();
    });
  }, [dispatch, selectedRow, fetch, setSelectedRow]);

  return <div className={b()}>
    <Title className={`mb ${b('title')}`}>
      Other Jobs
    </Title>
    <div className={`mb ${b('buttons')}`}>
      <Button
          onClick={cancelJobs}
          disabled={!selectedRow.length}
          startIcon={props.isLoading || cancelLoading ? <LoadingOutlined /> : <CloseCircleOutlined />}
          size="large"
          color="secondary"
          variant="contained"
      >
        Cancel Jobs
      </Button>
    </div>
    <JobFilters filters={props.filters} onFilter={onFilter} className="mb" withCodeFilter={false} />
    <JobsTable {...props} nameLocalStore="otherJobs" selected={selectedRow} setSelected={setSelectedRow} />
  </div>;
}

export default OtherJob;
