import React from 'react';
import AmazonOutlined from '@ant-design/icons/AmazonOutlined';
import FileUnknownOutlined from '@ant-design/icons/FileUnknownOutlined';
import EbayIcon from '../../../../Components/Icons/EbayIcon';
import './styles.scss';

const TYPE_TO_ICON = {
  amazon: <AmazonOutlined style={{ fontSize: 24}} />,
  ebay: <EbayIcon className="ProductType" />,
};

function ProductType({ productType }) {

  return TYPE_TO_ICON[productType] || <FileUnknownOutlined />;
}

export default ProductType;
