import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';

function ClientSelector({ className, value, onChange, defaultValue, placeholder, disabled, size }) {
  const { payload, isLoading } = useSelector(state => state.clients.list, shallowEqual);

  return (
    <Select defaultValue={defaultValue}
            placeholder={placeholder}
            className={className}
            loading={isLoading}
            size={size}
            value={value}
            onChange={onChange}
            style={{ width: '100%' }}
            disabled={disabled}
    >
      {payload && payload.map(client => (<Select.Option value={client.id}>{client.name}</Select.Option>))}
    </Select>
  );
}

ClientSelector.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ClientSelector;
