import React, { useCallback, useState } from 'react';
import b_ from 'b_';
import { message } from 'antd';
import Title from 'antd/lib/typography/Title';
import useTableState from '../../Utils/useTableState';
import { cancelInvoiceJobs, fetchInvoiceJobs } from '../../Reducers/invoiceJobs';
import JobsTable from '../../Components/JobsTable';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import JobFilters from '../../Components/JobFilters';
import './styles.scss';
import ErrorBlock from '../../Components/ErrorBlock';
import {Button} from "@material-ui/core";
import {CloseCircleOutlined} from "@ant-design/icons/lib/icons";

const b = b_.lock('InvoiceJobs');

function InvoiceJobs() {
  const { fetch, error, onFilter, ...props } = useTableState(state => state.invoiceJobs.list, fetchInvoiceJobs);
  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch();

  const cancelLoading = useSelector(state => state.invoiceJobs.cancel.isLoading, shallowEqual);

  const cancelJobs = useCallback(() => {
    dispatch(cancelInvoiceJobs(selectedRows)).then(() => {
      message.success('Jobs has been canceled!');
      setSelectedRows([]);
      fetch();
    });
  }, [dispatch, selectedRows, fetch, setSelectedRows]);

  return <div className={b()}>
    <Title className={`mb ${b('title')}`}>
      Invoice Jobs
    </Title>
    <div className={`mb ${b('buttons')}`}>
        <Button
            onClick={cancelJobs}
            disabled={!selectedRows.length}
            startIcon={<CloseCircleOutlined />}
            color="secondary"
            variant="contained"
            size="large"
            loading={props.isLoading || cancelLoading}
        >
          Cancel Jobs
        </Button>
    </div>
    {error && <ErrorBlock className="mb" message={error.message || error} />}
    <JobFilters filters={props.filters} onFilter={onFilter} className="mb" />
    <JobsTable {...props} nameLocalStore="invoiceJobs"  selected={selectedRows} setSelected={setSelectedRows} />
  </div>;
}

export default InvoiceJobs;
