import { createAction, createReducer } from '@reduxjs/toolkit';
import ProductService from '../Services/ProductService';
import { getDefaultHandler, getDefaultState } from '../Utils/newReducerTools';

export const fetchProductList = createAction('products/getList', () => ({
  payload: ProductService.getList(),
}));

export const createProduct = createAction('products/create', (data) => ({
  payload: ProductService.createProduct(data),
}));

const initState = {
  list: getDefaultState(),
  create: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(fetchProductList, 'list'),
  ...getDefaultHandler(createProduct, 'create'),
});
