import React, { useState } from 'react';
import frc from 'front-end-common';
import { message, Select, Spin } from 'antd';
import DriverService from '../../Services/DriverService';

const { useDebounce, useRequest } = frc.hooks;

function VpnLookup({ provider, onChange, value }) {
  const [search, setSearch] = useState('');
  const { fetch, state: { payload, isLoading } } = useRequest(DriverService.vpnLookup);

  useDebounce(() => {
    if (!search.trim()) return;

    fetch(provider, search).catch(() => {
      message.error('Something went wrong!');
    });
  }, 500, [search]);

  return (
    <Select onSelect={onChange}
            value={value}
            loading={isLoading}
            showSearch
            onSearch={setSearch}
            notFoundContent={isLoading ? <div style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}><Spin size="small" /></div> : null}
            filterOption={false}
            allowClear
            style={{ width: '100%' }}
    >
      {(payload || []).map(v => (<Select.Option key={v} value={v}>{v}</Select.Option>))}
    </Select>
  );
}

export default VpnLookup;
