import React, { useCallback } from 'react';
import { Drawer, Form, message, Input } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ErrorBlock from '../../../Components/ErrorBlock';
import { createDriver } from '../../../Reducers/driver';
import DriverStatusSelector from '../../../Components/DriverStatusSelector';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import frc from 'front-end-common';
import DriversTypeSelector from '../../../Components/DriversTypeSelector';
import DriversEngineSelector from '../../../Components/DriversEngineSelector';
import {Button} from "@material-ui/core";
import {LoadingOutlined} from "@ant-design/icons";

const useBoolean = frc.hooks.useBoolean;

function CreateDriver({ className, afterUpdate }) {
  const { setTrue: open, setFalse: close, value: isOpen } = useBoolean(false);
  const { isLoading, error } = useSelector(state => state.driver.create, shallowEqual)
  const dispatch = useDispatch();

  const handleSuccess = useCallback((data) => {
    dispatch(createDriver(data)).then(() => {
      message.success('Driver has been updated!');
      afterUpdate();
      close();
    })
  }, [dispatch, afterUpdate, close]);

  return (
    <>
      <Button
          color="primary"
          variant="contained"
          size="large"
          startIcon={<PlusOutlined />}
          className={className}
          style={{ backgroundColor: "#1976d2"}}
          onClick={open}
      >
        Create
      </Button>
      <Drawer onClose={close}
              visible={isOpen}
              title="Create Driver"
              placement="right"
              width={600}
      >
        {error && <ErrorBlock message={error.message} description={error.description} />}
        <Form layout="vertical" onFinish={handleSuccess}>
          <Form.Item
            rules={[
              { required: true, message: 'Please select group.' },
            ]}
            label="Group"
            name="group"
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please select driver.' },
            ]}
            label="Driver"
            name="driver"
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please select host.' },
            ]}
            label="Host"
            name="host"
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please select port.' },
            ]}
            label="Port"
            name="port"
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please select type.' },
            ]}
            label="Type"
            name="type"
          >
            <DriversTypeSelector />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please select status.' },
            ]}
            label="Status"
            name="status"
          >
            <DriverStatusSelector />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please input engineId.' },
            ]}
            label="Engine ID"
            name="engineId"
          >
            <DriversEngineSelector />
          </Form.Item>
          <Form.Item>
            <Button
                color="primary"
                variant="contained"
                type="submit"
                style={{ backgroundColor: "#1976d2"}}
                startIcon={isLoading && <LoadingOutlined />}>
              Create
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default CreateDriver;
