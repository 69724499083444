import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

function ProductName({ productName }) {
  return <Tooltip title={productName}>
    {productName.length > 35 ? productName.slice(0, 30) + '...' : productName}
  </Tooltip>
}

ProductName.propTypes = {
  productName: PropTypes.string.isRequired,
}

export default ProductName;
