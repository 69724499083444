import React from 'react';
import PropTypes, { number } from 'prop-types';

function PrintPrice({ className, value, currency }) {
  if (typeof value !== 'number' || Number.isNaN(value)) {
    return <i>none</i>
  }

  return <span className={className}>
    {value.toFixed(2).replace('.00', '')}
    {currency && <b className="ml-small">{currency}</b>}
  </span>;
}

PrintPrice.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  currency: PropTypes.string,
};

PrintPrice.defaultProps = {
  className: '',
  currency: '',
};

export default PrintPrice;
