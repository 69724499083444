import { createAction, createReducer } from '@reduxjs/toolkit';
import { getDefaultHandler, getDefaultState } from '../Utils/newReducerTools';
import OtherJobService from '../Services/OtherJobService';

export const fetchOtherJobs = createAction('otherJobs/list', (params) => ({
  payload: OtherJobService.getList(params),
}));

export const cancelOtherJobs = createAction('otherJobs/cancel', (ids) => ({
  payload: OtherJobService.cancel(ids),
}));

const initState = {
  list: getDefaultState(),
  cancel: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(fetchOtherJobs, 'list'),
  ...getDefaultHandler(cancelOtherJobs, 'cancel'),
});
