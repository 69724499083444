import React from 'react';
import { Modal} from 'antd';
import {Carousel} from 'react-responsive-carousel';
import useBoolean from '../../Utils/useBoolean';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {Button} from "@material-ui/core";
import {EyeOutlined} from "@ant-design/icons";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function ImageViews({className, images}) {
  const {setTrue: open, value: isOpen, setFalse: close} = useBoolean(false);

  return <>
    <Button
        className={className}
        size="small"
        color="primary"
        variant="contained"
        style={{backgroundColor: "#1976d2"}}
        onClick={open}
        startIcon={<EyeOutlined />}
        disabled={!images.length}>
      Show ({images.length})
    </Button>
    <Modal width={600} visible={isOpen} destroyOnClose closable onCancel={close} footer={null}>
      <Carousel className="p-16">
        {images.map((img, key) => (
          <div key={key}>
            <img src={BACKEND_URL + img} alt=""/>
          </div>
        ))}
      </Carousel>
    </Modal>
  </>;
}

export default ImageViews;
