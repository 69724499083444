import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import b_ from 'b_';
import Title from 'antd/lib/typography/Title';
import useTableState from '../../Utils/useTableState';
import { fetchAccounts, fetchAuxAccount } from '../../Reducers/accounts';
import AccountsTable from './AccountsTable';
import CreateAccount from './CreateAccount';
import UpdateAccounts from './UpdateAccounts';
import AccountGroupBalance from './AccountGroupBalanceTable';
import { useDispatch } from 'react-redux';
import DisableButton from './DisableButton';
import EnableButton from './EnableButton';
import ErrorBlock from '../../Components/ErrorBlock';
import './styles.scss';
import { fetchConfigs } from '../../Reducers/config';
import AccountsFilters from './AccountsFilter';
import {fetchClientsList} from "../../Reducers/clients";
import useStorageState from "../../Utils/useStorageState";

const b = b_.lock('Accounts');

function Accounts() {
  const {
    list,
    pagination,
    onChange,
    onFilter,
    isLoading,
    fetch,
    filters,
    sort,
    error,
    onSearch,
    query
  } = useTableState(state => state.accounts.list, fetchAccounts);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAuxAccount());
    dispatch(fetchConfigs());
    dispatch(fetchClientsList());
  }, [dispatch]);

  const [selectedRow, setSelectedRow] = useState([]);

  const [ accountsChosen, setAccountsChosen ] = useStorageState('accountsChosenResellM', {nameGroups:[], groups: {}});

  const addAccountChosen = (id, group) => {
      setAccountsChosen((e) =>{
          const objAcc = {
              nameGroups: [...e.nameGroups],
              groups: {...e.groups}
          }

          if(!objAcc.groups[group] ) {
              objAcc.groups[group] = []
          }
          const typeGroupNew = objAcc.nameGroups.indexOf(group) === -1
              ? [...objAcc.nameGroups, group]
              : [...objAcc.nameGroups.filter(s => s !== group), group]

          const accountsIdNew = objAcc.groups[group].indexOf(id) === -1
              ?  [...objAcc.groups[group], id]
              : [...objAcc.groups[group].filter(s => s !== id), id]

          objAcc.nameGroups = typeGroupNew
          objAcc.groups[group] = accountsIdNew

          return  objAcc
      })
  }

  const removeAccountChosen = (id, group) => {
      setAccountsChosen((e) =>{
          const objAcc = {
              nameGroups: [...e.nameGroups],
              groups: {...e.groups}
          }

          const typeGroupRemove = [...objAcc.nameGroups.filter(gr => gr !== group)]
          const accountsIdRemove = []

          objAcc.nameGroups = typeGroupRemove
          objAcc.groups[group] = accountsIdRemove

          return  objAcc
      })
  }

    const removeAccountFromGroup = (id, groups) => {
        setAccountsChosen((e) =>{
            const objAcc = {
                nameGroups: [...e.nameGroups],
                groups: {...e.groups}
            }

            const massGr = objAcc.nameGroups.filter((elem, key) => groups.indexOf(elem) === -1)

             if(massGr.length) {
                 objAcc.nameGroups.filter(es => {
                     if( massGr.indexOf(es) !== -1) {
                         return objAcc.groups[es] = objAcc.groups[es].filter(e => e !== id)
                     }
                     if( massGr.indexOf(es) === -1) {
                         return objAcc.groups[es] = [...objAcc.groups[es].filter(e => e !== id), id]
                     }
                 })
             }
             if(!massGr.length) {
                 objAcc.nameGroups.forEach(e => objAcc.groups[e].indexOf(id) === -1 ?
                     objAcc.groups[e] = [...objAcc.groups[e], id]
                     :[...objAcc.groups[e].filter(s => s !== id), id] )
             }
            return  objAcc
        })
    }

    useEffect(e => {
        if(( filters.ids && accountsChosen.groups[filters.group]) ) {
            onFilter({
                ids: accountsChosen.groups[filters.group].join(',') || [],
                group: filters.group,
            })
        }
    },[accountsChosen])

  return <div className={b()}>
    <Title className="mb">
      Accounts
    </Title>
    <AccountGroupBalance className="mb" />
    <div className={b('accountBlock')} style={{flexWrap: 'wrap'}}>
      <CreateAccount className="mr" afterSuccess={fetch} />
      <UpdateAccounts className="mr" afterSuccess={fetch} selected={selectedRow} />
      <DisableButton className="mr" selected={selectedRow} afterSuccess={fetch} />
      <EnableButton selected={selectedRow} afterSuccess={fetch} />
    </div>
    {error && <ErrorBlock className="mb" message={error.message} description={error.description} />}
    <Input.Search defaultValue={query} onSearch={onSearch} className={["mb", b("input_search")]} size="large" />
    <AccountsFilters className="mb" onFilter={onFilter} accounts={accountsChosen} filters={filters} />
    <AccountsTable selected={selectedRow}
                   addAccountChosen={addAccountChosen}
                   removeAccountChosen={removeAccountChosen}
                   removeAccountFromGroup={removeAccountFromGroup}
                   accountsLocal={accountsChosen}
                   setSelected={setSelectedRow}
                   afterUpdate={fetch}
                   list={list}
                   pagination={pagination}
                   onChange={onChange}
                   onFilter={onFilter}
                   isLoading={isLoading}
                   filters={filters}
                   sorter={sort}
    />
  </div>;
}

export default Accounts;
