import React from 'react';
import b_ from 'b_';
import { Layout as AntLayout } from 'antd';
import { Outlet } from 'react-router-dom';
import Header from '../Header';
import './styles.scss';

const Content = AntLayout.Content;

const b = b_.lock('Layout');

function Layout() {
  return <AntLayout  className={b()}>
    <AntLayout style={{flexDirection: "column"}}>
      <Header className={b('header', { isCollapsed: true })} />
      <Content className={b('content', { isCollapsed: true })}>
        <Outlet />
      </Content>
    </AntLayout>
  </AntLayout>;
}

export default Layout;
