import { createAction, createReducer } from '@reduxjs/toolkit';
import { getDefaultHandler, getDefaultState } from '../Utils/newReducerTools';
import AccountService from '../Services/AccountService';

export const fetchAccounts = createAction('accounts/list', (params) => ({
  payload: AccountService.getList(params),
}));

export const updateProxyAccount = createAction('accounts/update-proxy', (params) => ({
  payload: AccountService.changeProxy(params),
}));

export const updateDriverAccount = createAction('accounts/update-driver', (param) => ({
  payload: AccountService.changeDriver(param),
}));

export const updateClientAccount = createAction('accounts/update-client', (params) => ({
  payload: AccountService.changeClient(params),
}));

export const updateLoginAccount = createAction('accounts/update-login', (param) => ({
  payload: AccountService.changeLogin(param),
}));

export const updateBalLimitAccount = createAction('accounts/update-balLimit', (param) => ({
  payload: AccountService.changeBalLimit(param),
}));

export const updatePurchLimitAccount = createAction('accounts/update-purchLimit', (param) => ({
  payload: AccountService.changePurchLimit(param),
}));

export const updateCountryAccount = createAction('accounts/update-country', (param) => ({
  payload: AccountService.changeCountry(param),
}));

export const updateEnabledJobsAccount = createAction('accounts/update-enabledJobs', (param) => ({
  payload: AccountService.changeEnabledJobs(param),
}));

export const updateCommentAccount = createAction('accounts/update-comment', (param) => ({
  payload: AccountService.changeComment(param),
}));

export const createAccount = createAction('accounts/create', (data) => ({
  payload: AccountService.createAccount(data),
}));

export const updateAccounts = createAction('accounts/updates', (data) => ({
  payload: AccountService.updateAccounts(data),
}));

export const changePasswordAccount = createAction('accounts/change-password', (data) => ({
  payload: AccountService.changePassword(data),
}));

export const changeTokenAccount = createAction('accounts/change-token', (data) => ({
  payload: AccountService.changeToken(data),
}));

export const changeStatusAccount = createAction('accounts/change-status', (data) => ({
  payload: AccountService.changeStatus(data),
}));

export const changeCurrency = createAction('accounts/change-currency', (data) => ({
  payload: AccountService.changeCurrency(data),
}));

export const fetchGroupBalanceAccount = createAction('accounts/fetch-group-balance', () => ({
  payload: AccountService.fetchAccountGroupBalance(),
}));

export const fetchAuxAccount = createAction('accounts/fetch-aux', () => ({
  payload: AccountService.fetchAux(),
}));

export const disableAccounts = createAction('accounts/disable', (data) => ({
  payload: AccountService.disableAccounts(data),
}));

export const enableAccounts = createAction('accounts/enable', (data) => ({
  payload: AccountService.enableAccounts(data),
}));

const initState = {
  list: getDefaultState(),
  updateProxy: getDefaultState(),
  updateLogin: getDefaultState(),
  updateBalLimit: getDefaultState(),
  updatePurchLimit: getDefaultState(),
  updateCountry: getDefaultState(),
  updateEnabledJobs: getDefaultState(),
  updateDriver: getDefaultState(),
  updateClient: getDefaultState(),
  changeStatus: getDefaultState(),
  updateComment: getDefaultState(),
  updateCurrency: getDefaultState(),
  create: getDefaultState(),
  updateAccounts: getDefaultState(),
  changePassword: getDefaultState(),
  changeToken: getDefaultState(),
  groupBalance: getDefaultState(),
  aux: getDefaultState(),
  disableAccounts: getDefaultState(),
  enableAccounts: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(fetchAccounts, 'list'),
  ...getDefaultHandler(changeCurrency, 'updateCurrency'),
  ...getDefaultHandler(updateProxyAccount, 'updateProxy'),
  ...getDefaultHandler(updateLoginAccount, 'updateLogin'),
  ...getDefaultHandler(updateBalLimitAccount, 'updateBalLimit'),
  ...getDefaultHandler(updatePurchLimitAccount, 'updatePurchLimit'),
  ...getDefaultHandler(updateCountryAccount, 'updateCountry'),
  ...getDefaultHandler(updateEnabledJobsAccount, 'updateEnabledJobs'),
  ...getDefaultHandler(updateDriverAccount, 'updateDriver'),
  ...getDefaultHandler(updateClientAccount, 'updateClient'),
  ...getDefaultHandler(updateCommentAccount, 'updateComment'),
  ...getDefaultHandler(createAccount, 'create'),
  ...getDefaultHandler(updateAccounts, 'updateAccounts'),
  ...getDefaultHandler(changePasswordAccount, 'changePassword'),
  ...getDefaultHandler(changeTokenAccount, 'changeToken'),
  ...getDefaultHandler(changeStatusAccount, 'changeStatus'),
  ...getDefaultHandler(fetchGroupBalanceAccount, 'groupBalance'),
  ...getDefaultHandler(fetchAuxAccount, 'aux'),
  ...getDefaultHandler(disableAccounts, 'disableAccounts'),
});
